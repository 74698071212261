import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';

import UPBProductCatalogCard from 'components/upb_viewer/UPBProductCatalogCard';

function UPBCatalogView({ products, productCount, productType }) {
  return (
    <div
      className={classNames(css(styles.container), 'd-flex flex-wrap justify-content-center mt-4')}
    >
      {productCount ? (
        products.map(product => (
          <UPBProductCatalogCard key={product.id} product={product} productType={productType} />
        ))
      ) : (
        <div className="p-5 text-muted h5">No products found for the given search text</div>
      )}
    </div>
  );
}
const styles = StyleSheet.create({
  container: {
    minHeight: 500,
  },
});

UPBCatalogView.propTypes = {
  products: PropTypes.array,
  productCount: PropTypes.number,
  productType: PropTypes.string,
};

export default UPBCatalogView;
