import React from 'react';
import PropTypes from 'prop-types';

import { Redirect, Route } from 'react-router-dom';

import useUserPermissions from 'lib/hooks/useUserPermissions';

function ProtectedRoute({ path, exact, component, permissions, fallbackUrl, hasAll = true }) {
  const hasUserPermissions = useUserPermissions();
  return (
    <Route path={path} exact={exact}>
      {hasUserPermissions(permissions, hasAll) ? component : <Redirect to={fallbackUrl} />}
    </Route>
  );
}

ProtectedRoute.propTypes = {
  path: PropTypes.string,
  exact: PropTypes.bool,
  component: PropTypes.object,
  permissions: PropTypes.array,
  fallbackUrl: PropTypes.string,
  hasAll: PropTypes.bool,
};

export default ProtectedRoute;
