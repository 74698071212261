import React, { useState } from 'react';

import { css, StyleSheet } from 'aphrodite';
import { Button, Row, Table } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { backgroundGrey } from 'lib/css/colors';
import Container from 'components/shared/Container';
import { convertToTitleCase } from 'lib/utils/stringUtils';
import CreateManufacturerPortalModal from 'components/manufacturer_portals/CreateManufacturerPortalModal';
import { DEFAULT_PAGE_SIZE } from 'lib/constants';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import { GLOBAL_VENDOR_MANUFACTURER_PORTAL_REQUESTS } from 'lib/networking/endpoints';
import PaginationComponent from 'components/shared/PaginationComponent';
import pointerOnHover from 'lib/css/pointerOnHover';
import useGet from 'lib/hooks/useGet';

function ManufacturerPortalCreateView() {
  const history = useHistory();
  const [showPortalRequestModal, setShowPortalRequestModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(DEFAULT_PAGE_SIZE);
  const {
    data: { portal_requests: portalRequets, count } = {},
    loading,
    refetch,
  } = useGet(GLOBAL_VENDOR_MANUFACTURER_PORTAL_REQUESTS, {
    limit: itemsPerPage,
    offset: itemsPerPage * (currentPage - 1),
  });

  if (loading) {
    return <LoadingSpinner short />;
  }

  return (
    <Container>
      <div className="mb-3 d-flex justify-content-between">
        <h3 className="font-weight-bold">Create Manufacturer Portals</h3>
        <Button
          className="mr-3 px-4"
          variant="outline-primary"
          onClick={() => setShowPortalRequestModal(true)}
        >
          Add to Queue
        </Button>
      </div>
      <Row>
        <div className="w-100 d-flex justify-content-end px-3 py-3">
          <PaginationComponent
            totalItems={count}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            disabled={loading}
          />
        </div>
      </Row>
      <Table className={css(styles.tableText)}>
        <thead className={css(styles.thead)}>
          <tr>
            <th>Distributor Name</th>
            <th>Manufacturer Name</th>
            <th>Brands</th>
            <th># of Products</th>
            <th>Status</th>
            <th />
          </tr>
        </thead>
        <tbody className={css(styles.tbody)}>
          {portalRequets?.length ? (
            portalRequets.map((portalRequet, index) => (
              <tr key={index}>
                <td>{portalRequet.distributor_name}</td>
                <td>{portalRequet.manufacturer_name}</td>
                <td>{portalRequet.brands}</td>
                <td>{portalRequet.product_count}</td>
                <td>{convertToTitleCase(portalRequet.status)}</td>
                <td>
                  <Button
                    variant="link"
                    className={pointerOnHover}
                    onClick={() => history.push(`/manufacturer-portal-requests/${portalRequet.id}`)}
                  >
                    View
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="100%" className="text-center">
                No manufacture portal requests found.
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {showPortalRequestModal ? (
        <CreateManufacturerPortalModal
          onHide={() => setShowPortalRequestModal(false)}
          refetch={refetch}
        />
      ) : null}
    </Container>
  );
}

const styles = StyleSheet.create({
  tableText: {
    fontSize: '0.95rem',
  },
  thead: {
    position: 'sticky-top',
    zIndex: 1,
    backgroundColor: backgroundGrey,
  },
  tbody: {
    zIndex: 0,
    position: 'relative',
  },
});

export default ManufacturerPortalCreateView;
