import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Badge, Card, Form } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { faCheck, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CreatableSelect from 'react-select/creatable';

import pointerOnHover from 'lib/css/pointerOnHover';

function MultiValueInputTypeView({
  attributeValues,
  setAttributeValue,
  isEditView = false,
  options,
}) {
  const [val, setVal] = useState(() => (attributeValues ? attributeValues.split('||') : []));
  const [inputValue, setInputValue] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);
  const [showAddForm, setShowAddForm] = useState(val.length === 0 && isEditView);

  const validOptions =
    options?.filter(opt => opt != null && opt != 'null' && opt.trim() !== '') || [];

  const handleAdd = () => {
    if (validOptions.length) {
      if (selectedOption && !val.includes(selectedOption.value)) {
        const updatedValues = [...val, selectedOption.value];
        updateValues(updatedValues);
      }
    } else {
      if (inputValue.trim()) {
        const updatedValues = [...val, inputValue.trim()];
        updateValues(updatedValues);
      }
    }
    setInputValue('');
    setSelectedOption(null);
    setShowAddForm(false);
  };

  const updateValues = values => {
    setVal(values);
    setAttributeValue(values.join('||'));
  };

  const handleRemove = index => {
    const updatedValues = val.filter((_, i) => i !== index);
    updateValues(updatedValues);
  };

  return (
    <div className="mt-1">
      {isEditView ? (
        <>
          <div className="d-flex flex-wrap gap-2 mb-3">
            {val.map((value, index) => (
              <Card
                key={index}
                className={classNames('shadow-sm rounded-pill mr-1', css(styles.cardMinWidth))}
              >
                <Card.Body className="d-flex justify-content-between align-items-center py-1 px-3">
                  <span className="mr-1">{value}</span>
                  <FontAwesomeIcon
                    icon={faTimes}
                    className={classNames(
                      css(styles.removeIcon),
                      pointerOnHover,
                      'text-danger ms-2',
                    )}
                    onClick={() => handleRemove(index)}
                  />
                </Card.Body>
              </Card>
            ))}
          </div>

          {showAddForm ? (
            <div className="d-flex gap-2 align-items-center mb-3">
              <div className={classNames('position-relative', css(styles.inputContainer))}>
                {validOptions.length ? (
                  <CreatableSelect
                    isClearable
                    options={options.map(opt => ({ value: opt, label: opt }))}
                    onChange={setSelectedOption}
                    onInputChange={setInputValue}
                    placeholder="Select or create a value"
                    styles={{
                      control: base => ({
                        ...base,
                        borderRadius: '50px',
                        minHeight: '31px',
                        borderColor: '#ced4da',
                        minWidth: '200px',
                        ':hover': {
                          borderColor: '#86b7fe',
                        },
                      }),
                      input: base => ({
                        ...base,
                        minWidth: '200px',
                      }),
                      menu: base => ({
                        ...base,
                        borderRadius: '8px',
                        marginTop: '4px',
                        minWidth: '200px',
                      }),
                    }}
                  />
                ) : (
                  <Form.Control
                    size="sm"
                    placeholder="Enter new value..."
                    value={inputValue}
                    onChange={e => setInputValue(e.target.value)}
                    className={classNames('rounded-pill ps-3', css(styles.inputContainer))}
                  />
                )}
                <div className={classNames(css(styles.inputButtons), 'position-absolute')}>
                  <Button
                    variant="link"
                    className={classNames(css(styles.linkButton), 'text-success mr-1')}
                    onClick={handleAdd}
                    disabled={validOptions.length ? !selectedOption : !inputValue.trim()}
                  >
                    <FontAwesomeIcon icon={faCheck} />
                  </Button>
                  <Button
                    variant="link"
                    className={classNames(css(styles.linkButton), 'text-secondary')}
                    onClick={() => setShowAddForm(false)}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <Button
              size="sm"
              variant="outline-primary"
              onClick={() => setShowAddForm(true)}
              className="rounded-pill px-3"
              disabled={options?.length === 0}
            >
              <FontAwesomeIcon icon={faPlus} className="me-2" />
            </Button>
          )}
        </>
      ) : (
        <div className="d-flex flex-wrap align-items-center">
          {val.map((value, index) => (
            <div key={index} className="d-flex align-items-center">
              <Badge pill bg="light" className={classNames(css(styles.badgeBorder), 'px-3 py-2')}>
                {value}
              </Badge>
              {index < val.length - 1 && <span className="mx-1 text-muted">•</span>}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

const styles = StyleSheet.create({
  removeIcon: {
    fontSize: '0.8rem',
  },
  cardMinWidth: {
    minWidth: '60px',
  },
  inputButtons: {
    right: '8px',
  },
  badgeBorder: {
    border: '1px solid #dee2e6',
  },
  inputContainer: {
    minWidth: '200px',
  },
  linkButton: {
    fontSize: '1.1rem !important',
    padding: '0 !important',
  },
});

MultiValueInputTypeView.propTypes = {
  attributeValues: PropTypes.string,
  setAttributeValue: PropTypes.func,
  isEditView: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.string),
};

export default MultiValueInputTypeView;
