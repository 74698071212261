import { useState } from 'react';

import SwalDialog from 'lib/utils/toast';

function useDownloadFiles() {
  //this hook can be used to download files from S3 buckets when an array of
  //file links are passed. To download the S3 buckets' CORS policy should allow
  //the UI origin.
  const [isDownloading, setIsDownloading] = useState(false);
  //eslint-disable-next-line
  const downloadFiles = async urls => {
    // this function fetch a file from a S3 bucket and convert that
    // into a blob object. Then creates a temporary link object and
    // Programmatically force a click to download it.
    setIsDownloading(true);
    for (const url of urls) {
      try {
        const response = await fetch(url);
        const blob = await response.blob();
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = url.split('/').pop();
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(link.href);
      } catch (error) {
        SwalDialog('error', `Error downloading the file: ${error.message}`, 'Error', 'center');
      }
    }
    setIsDownloading(false);
  };

  return { downloadFiles, isDownloading };
}

export default useDownloadFiles;
