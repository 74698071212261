import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from 'react-bootstrap';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import LoadingSpinner from 'components/shared/LoadingSpinner';
import pointerOnHover from 'lib/css/pointerOnHover';
import ProductImagesSection from 'components/upb_viewer/pdp_components/images_section/ProductImagesSection';
import useDownloadFiles from 'lib/hooks/useDownloadFiles';
import { SupportTicketOrigins } from 'lib/enums/SupportTicketEnums';

function ImagesDisplayModal({ images, onHide, origin }) {
  const { downloadFiles, isDownloading } = useDownloadFiles();
  const handleDownloadAll = () => {
    const urls = images.map(file => file.url);
    if (origin !== SupportTicketOrigins.FSA) {
      downloadFiles(urls);
    } else {
      for (const url of urls) {
        window.open(url, '_blank');
      }
    }
  };

  return (
    <Modal show={true} onHide={onHide} size="xl" className="lato" centered>
      {isDownloading && (
        <div className={classNames(css(styles.overlay))}>
          <LoadingSpinner />
        </div>
      )}
      <Modal.Header closeButton>
        <div className={classNames(css(styles.headerContent))}>
          <Modal.Title>
            <strong>Attachments</strong>
          </Modal.Title>
          <OverlayTrigger overlay={<Tooltip>Download all attachments</Tooltip>}>
            <FontAwesomeIcon
              icon={faDownload}
              className={classNames(pointerOnHover, 'ml-3')}
              onClick={handleDownloadAll}
            />
          </OverlayTrigger>
        </div>
      </Modal.Header>
      <Modal.Body>
        <ProductImagesSection images={images} />
      </Modal.Body>
    </Modal>
  );
}

const styles = StyleSheet.create({
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    zIndex: 1050,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerContent: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
});

ImagesDisplayModal.propTypes = {
  images: PropTypes.array.isRequired,
  onHide: PropTypes.func.isRequired,
  origin: PropTypes.string.isRequired,
};

export default ImagesDisplayModal;
