import React, { useState, useEffect } from 'react';
import { StyleSheet } from 'aphrodite';
import { Col, Form, Row, Table } from 'react-bootstrap';
import Select from 'react-select';
import { SUPPORT_TICKET } from 'lib/networking/endpoints';
import { useParams, useHistory } from 'react-router-dom';

import Container from 'components/shared/Container';
import Cookies from 'lib/enums/Cookies';
import {
  GLOBAL_MANUFACTURERS_FOR_DROPDOWN,
  GLOBAL_VENDORS_FOR_DROPDOWN,
  USERS_GET_FOR_DROPDOWN,
} from 'lib/networking/endpoints';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import SearchableScrollableSelect from 'components/shared/SearchableScrollableSelect';
import SupportTicketsTableRow from 'components/settings/support_tickets/SupportTicketsTableRow';
import { SupportTicketStatus, SupportTicketStatusOptions } from 'lib/enums/SupportTicketEnums';
import useGet from 'lib/hooks/useGet';
import useLocalStorage from 'lib/hooks/useLocalStorage';
import SupportTicketsModal from 'components/settings/support_tickets/SupportTicketsModal';

function SupportTicketsView() {
  const [cachedFilters, setCachedFilters] = useLocalStorage(Cookies.SUPPORT_TICKET_FILTERS, {});
  const [selectedStatus, setSelectedStatus] = useState(
    cachedFilters.status || [
      SupportTicketStatus.PENDING_REVIEW,
      SupportTicketStatus.TO_DO,
      SupportTicketStatus.IN_PROGRESS,
    ],
  );
  const [selectedUser, setSelectedUser] = useState(cachedFilters.user || {});
  const [selectedVendor, setSelectedVendor] = useState({});
  const [selectedManufacturer, setSelectedManufacturer] = useState({});

  const [showSupportTicketsModal, setShowSupportTicketsModal] = useState(false);

  const { ticketId: ticketIdParam } = useParams();
  const ticketId = parseInt(ticketIdParam, 10);
  const history = useHistory();

  const {
    data: { tickets, count } = {},
    refetch: refetch,
    loading: loading,
  } = useGet(SUPPORT_TICKET, {
    status: selectedStatus,
    vendor_id: selectedVendor?.value || null,
    manufacturer_id: selectedManufacturer?.value || null,
    assigned_to_id: selectedUser?.value || null,
  });
  useEffect(() => {
    if (ticketId) {
      setShowSupportTicketsModal(true);
    }
  }, [ticketId]);
  return (
    <Container>
      <Row className="ml-2">
        <div className="mb-3 d-flex justify-content-between">
          <h3 className="font-weight-bold">Support Tickets</h3>
        </div>
      </Row>
      <Row className="ml-2">
        <Col sm={4}>
          <Form.Group>
            <Form.Label>Status</Form.Label>
            <Select
              isMulti
              isClearable
              value={SupportTicketStatusOptions.filter(option =>
                selectedStatus.includes(option.value),
              )}
              options={SupportTicketStatusOptions}
              onChange={options => {
                setSelectedStatus(options ? options.map(option => option.value) : []);
                setCachedFilters({
                  status: options ? options.map(option => option.value) : [],
                  user: selectedUser,
                });
              }}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Distributor</Form.Label>
            <SearchableScrollableSelect
              initOption={selectedVendor}
              onChange={option => setSelectedVendor(option)}
              fetchUrl={GLOBAL_VENDORS_FOR_DROPDOWN}
              optionMapper={vendor => ({ label: vendor.name, value: vendor.id })}
              autoFocus={false}
              disabled={selectedManufacturer?.value}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Manufacturer</Form.Label>
            <SearchableScrollableSelect
              initOption={selectedManufacturer}
              onChange={option => setSelectedManufacturer(option)}
              fetchUrl={GLOBAL_MANUFACTURERS_FOR_DROPDOWN}
              optionMapper={vendor => ({ label: vendor.name, value: vendor.id })}
              autoFocus={false}
              disabled={selectedVendor?.value}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Assigned To</Form.Label>
            <SearchableScrollableSelect
              initOption={selectedUser}
              onChange={option => {
                setSelectedUser(option);
                setCachedFilters({
                  user: option,
                  status: selectedStatus,
                });
              }}
              fetchUrl={USERS_GET_FOR_DROPDOWN}
              optionMapper={user => ({ label: user.name, value: user.id })}
              placeholder="Select user"
              autoFocus={false}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="ml-2">
        <Col className="d-flex justify-content-start">
          <span className="text-secondary ml-2">{count} Tickets found</span>
        </Col>
      </Row>
      <Row className="ml-2 mr-2">
        {loading ? (
          <LoadingSpinner />
        ) : (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>ID</th>
                <th>Task</th>
                <th>Origin</th>
                <th>Product Entity Type</th>
                <th>Entity Name</th>
                <th>Status</th>
                <th>Assigned To</th>
                <th>Age</th>
              </tr>
            </thead>
            <tbody>
              {tickets?.map(ticket => (
                <SupportTicketsTableRow
                  key={ticket.id}
                  ticket={ticket}
                  refetch={refetch}
                  onClick={() => {
                    history.push(`/support-tickets/${ticket.id}`);
                    setShowSupportTicketsModal(true);
                  }}
                />
              ))}
            </tbody>
          </Table>
        )}
      </Row>
      {showSupportTicketsModal && (
        <SupportTicketsModal
          ticketId={ticketId}
          onHide={ticket => {
            setShowSupportTicketsModal(false);
            history.push('/support-tickets');
          }}
          refetch={refetch}
        />
      )}
    </Container>
  );
}

const styles = StyleSheet.create({
  selectContainer: {
    width: '300px',
  },
});

export default SupportTicketsView;
