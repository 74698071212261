const AttributeFlagType = {
  BEST: 'BEST',
  CUSTOM: 'CUSTOM',
};

const AttributeFlagTypeOptions = [
  { value: AttributeFlagType.BEST, label: 'Best' },
  { value: AttributeFlagType.CUSTOM, label: 'Custom' },
];

export { AttributeFlagType, AttributeFlagTypeOptions };
