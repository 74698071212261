import React from 'react';
import PropTypes from 'prop-types';

import { Button, Table } from 'react-bootstrap';

import pointerOnHover from 'lib/css/pointerOnHover';

function ManufacturerPortalProductsTableView({ products }) {
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>SKU</th>
          <th>Name</th>
          <th>Brand</th>
          <th>Manufacturer</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {products.length ? (
          products.map(product => (
            <tr key={product.id} className={pointerOnHover}>
              <td>{product.vendor_sku}</td>
              <td>{product.name}</td>
              <td>{product.brand.name}</td>
              <td>{product.manufacturer_name}</td>
              <td>
                <Button
                  variant="link"
                  onClick={() => {
                    window.open(
                      `/global-product-detail/` +
                        `${product.global_product_id}/${product.id}/distributor`,
                      '_blank',
                    );
                  }}
                >
                  View
                </Button>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td className="text-center" colSpan="100%">
              No products found for the given filters
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}

ManufacturerPortalProductsTableView.propTypes = {
  products: PropTypes.array,
};

export default ManufacturerPortalProductsTableView;
