import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import {
  Button,
  Col,
  Container,
  Form,
  Image,
  OverlayTrigger,
  Row,
  Tab,
  Tabs,
  Tooltip,
} from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import classNames from 'classnames';
import { faCertificate } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';

import { anchovy, backgroundGrey4, contentVerifiedBlue, iconGrey } from 'lib/css/colors';
import ActionDropDown from 'components/shared/ActionDropDown';

import { AttributeFlagTypeOptions } from 'lib/enums/AttributeFlagType';
import { CHANGE_LOG_TYPE } from 'lib/enums/ChangeLogEnums';
import CloneVendorProductModal from 'components/global_products/detail_view/CloneVendorProductModal';
import {
  getURL,
  GLOBAL_PRODUCT,
  GLOBAL_PRODUCT_RESET_CACHE,
  GLOBAL_PRODUCT_VENDOR_MARK_AS_DEMO,
  GLOBAL_PRODUCT_VENDOR_UPDATE_ATTRIBUTE_FLAG,
} from 'lib/networking/endpoints';
import { CONTENT_VERIFICATION_STATUSES } from 'lib/enums/ContentVerificationStatus';
import EditableFieldV2 from 'components/shared/EditableFieldV2';
import { GLOBAL_PRODUCT_TYPE, ProductType } from 'lib/enums/ProductType';
import GlobalProductsAttributesView from 'components/global_products/detail_view/GlobalProductsAttributesView';
import { GlobalProductsDetailContext } from 'components/global_products/detail_view/GlobalProductsDetailContainer';
import GlobalProductAssetView from 'components/global_products/detail_view/assets_view/GlobalProductAssetView';
import GlobalProductsChangelogView from 'components/global_products/detail_view/GlobalProductsChangelogView';
import GlobalProductsRelationshipsView from 'components/global_products/detail_view/GlobalProductsRelationshipsView';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import placeholderProductImage from 'images/placeholder-img-product-v2.svg';
import { PRODUCT_DETAIL_VIEW_TABS } from 'lib/constants';
import ProductsAttributesView from 'components/global_products/detail_view/attributes_view/ProductsAttributesView';
import UPBProductDetailsPage from 'components/upb_viewer/UPBProductDetailsPage';
import useGet from 'lib/hooks/useGet';
import usePost from 'lib/hooks/usePost';
import usePut from 'lib/hooks/usePut';
import useQueryString from 'lib/hooks/useQueryString';

function GlobalProductsDetailView() {
  const { globalProductId, productID, productType } = useParams();
  const tab = useQueryString().get('tab');
  const [showCloneModal, setShowCloneModal] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState(tab || PRODUCT_DETAIL_VIEW_TABS.PDP_PREVIEW);
  const [searchInput, setSearchInput] = useState('');
  const [selectedProductOption, setSelectedProductOption] = useState(null);
  const previousSelectedProductOption = useRef(null);
  const { setOtherImages, thumbnailImage, setThumbnailImage, setLoading, cndProductScore } =
    useContext(GlobalProductsDetailContext);
  const [attributeFlagType, setAttributeFlagType] = useState(null);
  const [isChanged, setIsChanged] = useState(false);
  const selectedVendorProductId =
    selectedProductOption?.productType === ProductType.DISTRIBUTOR
      ? selectedProductOption?.productId
      : null;
  const selectedManufacturerProductId =
    selectedProductOption?.productType === ProductType.MANUFACTURER
      ? selectedProductOption?.productId
      : null;

  const isADemoProduct =
    selectedProductOption?.productType === ProductType.DISTRIBUTOR
      ? selectedProductOption?.product.is_demo_product
      : null;

  const {
    data: { data: globalProduct } = {},
    loading,
    error,
    refetch,
  } = useGet(getURL(GLOBAL_PRODUCT, { productId: globalProductId }), {}, !globalProductId);

  const { postData: markAsDemoProduct, loading: markingAsDemoProduct } = usePost(
    GLOBAL_PRODUCT_VENDOR_MARK_AS_DEMO,
    () => {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: isADemoProduct
          ? 'Product marked as not a demo product successfully'
          : 'Product marked as a demo product successfully',
      }).then(() => {
        refetch();
      });
    },
    error =>
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text:
          `An error occurred while marking the product as a demo: ` +
          ` ${error?.response?.data?.message}`,
      }),
  );

  const { postData: updateAttributeFlag, loading: updatingAttributeFlag } = usePost(
    GLOBAL_PRODUCT_VENDOR_UPDATE_ATTRIBUTE_FLAG,
    () => {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Data source priority updated successfully',
      }).then(() => {
        refetch();
      });
    },
    error =>
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text:
          `An error occurred while updating data source priorityo: ` +
          ` ${error?.response?.data?.message}`,
      }),
  );

  const { putData: updateGlobalProduct } = usePut(
    getURL(GLOBAL_PRODUCT, { productId: globalProductId }),
    () => {
      Swal.fire({
        icon: 'success',
        text: 'Successfully updated',
        title: 'Success',
      });
      refetch();
    },
    error =>
      Swal.fire({
        icon: 'error',
        text: error?.response?.data?.message || 'Something went wrong, please try again.',
        title: 'Error',
        showCancelButton: true,
        confirmButtonText: 'Proceed And Merge',
        cancelButtonText: 'Cancel',
      }).then(result => {
        if (result.isConfirmed) {
          // access the payload and get the gtin
          const requestData = error?.response?.config?.data;
          const parsedData =
            typeof requestData === 'string' ? JSON.parse(requestData) : requestData;
          const gtin = parsedData?.gtin;
          if (gtin) {
            updateGlobalProduct({ gtin, merge_if_existing: true });
          }
        }
      }),
  );

  const { postData: resetCache, loading: resttingCache } = usePost(
    GLOBAL_PRODUCT_RESET_CACHE,
    () => {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Product cache reset successfully',
      }).then(() => {
        refetch();
      });
    },
    error =>
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text:
          `An error occurred while resetting the product cache: ` +
          ` ${error?.response?.data?.message}`,
      }),
  );

  const selectedProductOptions = useMemo(() => {
    if (!globalProduct) {
      return [];
    }
    const options = [
      {
        label: 'Global Product',
        value: `global-${globalProductId}`,
        productId: globalProduct.id,
        productType: GLOBAL_PRODUCT_TYPE,
        product: globalProduct,
      },
    ];
    globalProduct?.global_product_manufacturers?.forEach(product =>
      options.push({
        label: `${product.name} (${product.global_manufacturer.name} \
          - ${product.manufacturer_sku})`,
        value: `manufacturer-${product.id}`,
        productId: product.id,
        productType: ProductType.MANUFACTURER,
        product,
      }),
    );
    globalProduct?.global_product_vendors?.forEach(product =>
      options.push({
        label: `${product.name} (${product.global_vendor.name} - ${product.vendor_sku})`,
        value: `vendor-${product.id}`,
        productId: product.id,
        productType: ProductType.DISTRIBUTOR,
        product,
      }),
    );
    return options;
  }, [globalProduct, globalProductId]);

  const handleSaveChanges = () => {
    updateAttributeFlag({
      vendor_product_id: selectedVendorProductId,
      attribute_flag: attributeFlagType,
    });
    setIsChanged(false);
  };

  // Preserve the selection across refetches
  useEffect(() => {
    if (selectedProductOption !== previousSelectedProductOption.current) {
      previousSelectedProductOption.current = selectedProductOption;
    }
  }, [selectedProductOption]);

  // Preserve and set the initial product selection
  useEffect(() => {
    if (previousSelectedProductOption.current) {
      setSelectedProductOption(previousSelectedProductOption.current);
    } else if (globalProduct && productID && productType) {
      const matchedProd = selectedProductOptions.filter(
        option => option?.productType === productType && option.productId.toString() === productID,
      );
      setSelectedProductOption(...matchedProd);
      if (productType === ProductType.DISTRIBUTOR) {
        setAttributeFlagType(matchedProd[0].product.attribute_flag);
      }
    } else if (globalProduct) {
      setSelectedProductOption({
        label: 'Global Product',
        value: `global-${globalProduct.id}`,
        productId: globalProduct.id,
        productType: GLOBAL_PRODUCT_TYPE,
        product: globalProduct,
      });
    }
  }, [globalProduct, productID, productType, selectedProductOptions]);

  useEffect(() => {
    setIsChanged(attributeFlagType !== selectedProductOption?.product?.attribute_flag);
  }, [attributeFlagType, selectedProductOption]);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error || !globalProduct) {
    return null;
  }

  const selectedProduct = selectedProductOption?.product;

  return (
    <Container fluid className={classNames(css(styles.container), 'py-3')}>
      <Row>
        <Col sm={3} className={css(styles.leftCol)}>
          <Image
            src={thumbnailImage?.url ?? placeholderProductImage}
            fluid
            className="mx-auto d-block w-100"
            alt="Product"
          />
          <div className="d-flex">
            <h5 className="font-weight-bold py-3">{selectedProduct?.name}</h5>
            <div className="mt-3 ml-2">
              <OverlayTrigger
                overlay={
                  <Tooltip>
                    {selectedProduct?.content_verification_status ===
                    CONTENT_VERIFICATION_STATUSES.VERIFIED
                      ? 'Content verified'
                      : 'Not content verified'}
                  </Tooltip>
                }
              >
                <FontAwesomeIcon
                  icon={faCertificate}
                  color={
                    selectedProduct?.content_verification_status ===
                    CONTENT_VERIFICATION_STATUSES.VERIFIED
                      ? contentVerifiedBlue
                      : iconGrey
                  }
                  style={{ fontSize: '24px' }}
                />
              </OverlayTrigger>
            </div>
          </div>
          <Row className="mt-3">
            <Col className={css(styles.attributeName)}>Product</Col>
          </Row>
          <Row className={css(styles.productSelect)}>
            <Col>
              <Select
                className="w-100"
                value={selectedProductOptions.filter(
                  option => option.value === selectedProductOption?.value,
                )}
                options={selectedProductOptions}
                onChange={option => {
                  setOtherImages([]);
                  setThumbnailImage({});
                  setLoading(true);
                  setSelectedProductOption(option);
                  setAttributeFlagType(option?.product?.attribute_flag);
                }}
              />
            </Col>
          </Row>
          <div className="mb-2">
            <p className={css(styles.attributeName)}>{`C+D Score - ${
              cndProductScore?.percentage || '-'
            } %`}</p>
          </div>
          {selectedProduct?.global_manufacturer ? (
            <div>
              <p className={css(styles.attributeName)}>Manufacturer SKU</p>
              <p className={css(styles.attributeValue)}>{selectedProduct?.manufacturer_sku}</p>
            </div>
          ) : (
            <div>
              <p className={css(styles.attributeName)}>Vendor SKU</p>
              <p className={css(styles.attributeValue)}>{selectedProduct?.vendor_sku}</p>
            </div>
          )}
          <div>
            <p className={css(styles.attributeName)}>GTIN</p>
            <EditableFieldV2
              value={globalProduct.gtin || '-'}
              setValue={e => updateGlobalProduct({ gtin: e })}
              editButtonSide="right"
            />
          </div>
          <div>
            <p className={css(styles.attributeName)}>GTIN ITEM</p>
            <p className={css(styles.attributeValue)}>{globalProduct.gtin_item || '-'}</p>
          </div>
          <div>
            <p className={css(styles.attributeName)}>UPC</p>
            <p className={css(styles.attributeValue)}>{globalProduct.upc || '-'}</p>
          </div>
          <div>
            <p className={css(styles.attributeName)}>Cut and Dry ID</p>
            <p className={css(styles.attributeValue)}>{selectedProduct?.cnd_id || '-'}</p>
          </div>
          <div>
            <p className={css(styles.attributeName)}>Global Product ID</p>
            <p className={css(styles.attributeValue)}>{globalProduct.id}</p>
          </div>
          <div>
            <p className={css(styles.attributeName)}>Created Date</p>
            <p className={css(styles.attributeValue)}>{selectedProduct?.date_created}</p>
          </div>
          <div>
            <p className={css(styles.attributeName)}>Last Modified Date</p>
            <p className={css(styles.attributeValue)}>{selectedProduct?.date_modified}</p>
          </div>
          {globalProduct.data_source_origin && (
            <div>
              <p className={css(styles.attributeName)}>Original Data Source</p>
              <p className={css(styles.attributeValue)}>{globalProduct.data_source_origin?.name}</p>
            </div>
          )}
          {globalProduct?.l4_category ? (
            <div>
              <div>
                <p className={css(styles.attributeName)}>L1 Category</p>
                <p className={css(styles.attributeValue)}>
                  {globalProduct.l4_category.l3_category.l2_category.l1_category.category_name}
                </p>
              </div>
              <div>
                <p className={css(styles.attributeName)}>L2 Category</p>
                <p className={css(styles.attributeValue)}>
                  {globalProduct.l4_category.l3_category.l2_category.category_name}
                </p>
              </div>
              <div>
                <p className={css(styles.attributeName)}>L3 Category</p>
                <p className={css(styles.attributeValue)}>
                  {globalProduct.l4_category.l3_category.category_name}
                </p>
              </div>
              <div>
                <p className={css(styles.attributeName)}>L4 Category</p>
                <p className={css(styles.attributeValue)}>
                  {globalProduct.l4_category.category_name}
                </p>
              </div>
            </div>
          ) : null}
        </Col>
        <Col sm={9}>
          {selectedProductOption?.productType === ProductType.DISTRIBUTOR && (
            <div className="mb-2 d-flex justify-content-end">
              <div className="mr-3 d-flex align-items-center">
                <h6 className="mr-3">
                  <strong>Data Source Priority</strong>
                </h6>
                <Form.Group>
                  <Select
                    isClearable
                    disabled={updatingAttributeFlag}
                    options={AttributeFlagTypeOptions}
                    value={AttributeFlagTypeOptions.find(
                      option => option.value === attributeFlagType,
                    )}
                    onChange={option => setAttributeFlagType(option?.value)}
                  />
                </Form.Group>
                {isChanged && (
                  <Button className="ml-3" variant="primary" onClick={handleSaveChanges}>
                    Save Changes
                  </Button>
                )}
              </div>
            </div>
          )}
          <div className="d-flex justify-content-end">
            <ActionDropDown
              className="ml-2"
              submenuItems={[
                {
                  title: 'Reset cache of the selected product',
                  action: () =>
                    resetCache({
                      vendor_product_id: selectedVendorProductId,
                      manufacturer_product_id: selectedManufacturerProductId,
                    }),
                  disabled: resttingCache,
                },
                {
                  title: 'Reset cache of all linked products in global product',
                  action: () => resetCache({ global_product_id: globalProductId }),
                  disabled: resttingCache,
                },
                {
                  title: isADemoProduct ? 'Unmark as a demo product' : 'Mark as a demo product',
                  action: () =>
                    markAsDemoProduct({
                      vendor_product_id: selectedVendorProductId,
                      demo_status: !isADemoProduct,
                    }),
                  disabled: markingAsDemoProduct || !selectedVendorProductId,
                },
                {
                  title: 'Clone this product',
                  action: () => setShowCloneModal(true),
                  disabled: !selectedVendorProductId,
                },
              ]}
              label={'Product Actions'}
            />
          </div>
          <Tabs
            className="border-top-0"
            defaultActiveKey={activeTabKey}
            onSelect={selectedKey => setActiveTabKey(selectedKey)}
            transition={false}
          >
            <Tab
              eventKey={PRODUCT_DETAIL_VIEW_TABS.PDP_PREVIEW}
              title={PRODUCT_DETAIL_VIEW_TABS.PDP_PREVIEW}
            >
              <UPBProductDetailsPage
                propProductId={selectedProductOption?.productId}
                productType={selectedProductOption?.productType}
                hideHeader
              />
            </Tab>
            <Tab
              eventKey={PRODUCT_DETAIL_VIEW_TABS.ATTRIBUTES}
              title={PRODUCT_DETAIL_VIEW_TABS.ATTRIBUTES}
              tabClassName={
                activeTabKey === PRODUCT_DETAIL_VIEW_TABS.ATTRIBUTES
                  ? css(styles.activeTab)
                  : css(styles.defaultTab)
              }
            >
              {selectedProductOption ? (
                <GlobalProductsAttributesView
                  productId={selectedProductOption.productId}
                  productType={selectedProductOption?.productType}
                  searchInput={searchInput}
                  setSearchInput={setSearchInput}
                  refetch={refetch}
                />
              ) : null}
            </Tab>
            <Tab
              eventKey={PRODUCT_DETAIL_VIEW_TABS.ATTRIBUTES_V1}
              title={PRODUCT_DETAIL_VIEW_TABS.ATTRIBUTES_V1}
              tabClassName={
                activeTabKey === PRODUCT_DETAIL_VIEW_TABS.ATTRIBUTES_V1
                  ? css(styles.activeTab)
                  : css(styles.defaultTab)
              }
            >
              {selectedProductOption ? (
                <ProductsAttributesView
                  productId={selectedProductOption.productId}
                  productType={selectedProductOption?.productType}
                  searchInput={searchInput}
                  setSearchInput={setSearchInput}
                  refetch={refetch}
                />
              ) : null}
            </Tab>
            <Tab
              eventKey={PRODUCT_DETAIL_VIEW_TABS.ASSETS}
              title={PRODUCT_DETAIL_VIEW_TABS.ASSETS}
              tabClassName={
                activeTabKey === PRODUCT_DETAIL_VIEW_TABS.ASSETS
                  ? css(styles.activeTab)
                  : css(styles.defaultTab)
              }
            >
              <GlobalProductAssetView
                refetch={refetch}
                selectedProductOption={selectedProductOption}
              />
            </Tab>
            <Tab
              eventKey={PRODUCT_DETAIL_VIEW_TABS.RELATIONSHIPS}
              title={PRODUCT_DETAIL_VIEW_TABS.RELATIONSHIPS}
              tabClassName={
                activeTabKey === PRODUCT_DETAIL_VIEW_TABS.RELATIONSHIPS
                  ? css(styles.activeTab)
                  : css(styles.defaultTab)
              }
            >
              <GlobalProductsRelationshipsView globalProduct={globalProduct} refetch={refetch} />
            </Tab>
            <Tab
              eventKey={PRODUCT_DETAIL_VIEW_TABS.CHANGE_LOG}
              title={PRODUCT_DETAIL_VIEW_TABS.CHANGE_LOG}
              tabClassName={
                activeTabKey === PRODUCT_DETAIL_VIEW_TABS.CHANGE_LOG
                  ? css(styles.activeTab)
                  : css(styles.defaultTab)
              }
            >
              <GlobalProductsChangelogView selectedProductOption={selectedProductOption} />
            </Tab>
          </Tabs>
        </Col>
      </Row>

      <CloneVendorProductModal
        showCloneModal={showCloneModal}
        setShowCloneModal={setShowCloneModal}
        vendorProductId={selectedVendorProductId}
        refetch={refetch}
      />
    </Container>
  );
}

const styles = StyleSheet.create({
  container: {
    minHeight: '50vh',
  },
  attributeTitle: {
    fontWeight: 'bold',
    color: anchovy,
    marginBottom: 4,
  },
  attributeName: {
    display: 'inline',
    fontWeight: 'bold',
    color: anchovy,
  },
  attributeValue: {
    display: 'block',
  },
  leftCol: {
    paddingLeft: '1.9rem',
    minHeight: '100vh',
    borderRight: '3px solid ' + backgroundGrey4,
  },
  categoryValue: {
    display: 'block',
  },
  productSelect: {
    marginBottom: '1.2rem',
  },
});

export default GlobalProductsDetailView;
