import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { backgroundGrey4 } from 'lib/css/colors';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import Select from 'react-select/creatable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { arraysAreEqual } from 'lib/utils/arrayUtils';
import DropZone from 'components/shared/Dropzone';
import ImagesDisplayModal from 'components/settings/support_tickets/ImagesDisplayModal';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import pointerOnHover from 'lib/css/pointerOnHover';
import { ProductTypeDisplay } from 'lib/enums/ProductType';
import SearchableScrollableSelect from 'components/shared/SearchableScrollableSelect';
import {
  CURRENT_USER,
  SUPPORT_TICKET_ADD_NEW_COMMENT,
  SUPPORT_TICKET_GET_BY_ID,
  SUPPORT_TICKETS_UPDATE,
  USERS_GET_FOR_DROPDOWN,
} from 'lib/networking/endpoints';
import SupportTicketCommentBox from 'components/settings/support_tickets/SupportTicketCommentBox';
import {
  SupportTicketOriginLabels,
  SupportTicketStatusOptions,
} from 'lib/enums/SupportTicketEnums';
import SwalDialog from 'lib/utils/toast';
import useGet from 'lib/hooks/useGet';
import usePost from 'lib/hooks/usePost';

function SupportTicketsModal({ ticketId, onHide, refetch }) {
  const { data: { ticket } = {}, loading: loading } = useGet(SUPPORT_TICKET_GET_BY_ID, {
    id: ticketId,
  });

  const images =
    ticket && ticket?.details.attachments.length > 0
      ? ticket?.details.attachments.map(imageUrl => ({
          url: imageUrl,
        }))
      : [];

  const initialState = useMemo(
    () => ({
      status: ticket?.status,
      assignedTo: {
        label: ticket?.assigned_to?.name || null,
        value: ticket?.assigned_to?.id || null,
      },
      comments: ticket?.comments || [],
    }),
    [ticket],
  );

  const [updatedState, setUpdatedState] = useState(initialState);
  const [newComment, setNewComment] = useState({ name: '', comment: '', attachments: [] });
  const [displayCommentOptions, setDisplayCommentOptions] = useState(false);
  const [showAttachmentsModal, setShowAttachmentsModal] = useState(false);
  const [showCommentsAttachmentsModal, setShowCommentsAttachmentsModal] = useState(false);

  const { data: { user } = {} } = useGet(CURRENT_USER);

  useEffect(() => {
    setUpdatedState(initialState);
  }, [initialState]);

  const { postData: updateTicket, loading: updatingTicket } = usePost(
    SUPPORT_TICKETS_UPDATE,
    () => {
      SwalDialog('success', `Successfully updated the ticket`, 'Success', 'center', () => {});
      refetch();
      onHide();
    },
    () => {
      SwalDialog('error', 'Error occurred while updating the ticket', 'Error', 'center');
    },
  );

  const hasChanges =
    updatedState.status !== initialState.status ||
    updatedState.assignedTo?.value !== initialState.assignedTo.value ||
    !arraysAreEqual(updatedState.comments, initialState.comments);

  const handleSaveChanges = () => {
    const updatedFields = {};
    if (updatedState.status !== initialState.status) updatedFields.status = updatedState.status;
    if (updatedState.assignedTo?.value !== initialState.assignedTo.value)
      updatedFields.assigned_to_id = updatedState.assignedTo.value;
    if (!arraysAreEqual(updatedState.comments, initialState.comments))
      updatedFields.comments = updatedState.comments;

    updateTicket({
      ticket_id: ticket?.id,
      ...updatedFields,
    });
  };

  const navigateToPDP = () => {
    if (ticket?.vendor_product) {
      window.open(
        `/global-product-detail/${ticket?.vendor_product.global_product.id}/` +
          `${ticket?.vendor_product.id}/distributor`,
        '_blank',
      );
    } else {
      window.open(
        `/global-product-detail/${ticket?.manufacturer_product.global_product.id}/` +
          `${ticket?.manufacturer_product.id}/manufacturer`,
        '_blank',
      );
    }
  };

  const handleCopyToClipboard = textToCopy => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        SwalDialog('success', 'Copied to clipboard', 'Success', 'center');
      })
      .catch(() => {
        SwalDialog('error', 'Failed to copy', 'Error', 'center');
      });
  };

  const sku = ticket?.vendor_product
    ? ticket?.vendor_product.vendor_sku
    : ticket?.manufacturer_product.manufacturer_sku;

  const handleAddNewComment = e => {
    const comment = {
      name: user?.name,
      comment: newComment.comment,
      date: new Date().toISOString().replace('T', ' ').substring(0, 19),
      attachments: newComment.attachments,
    };
    setUpdatedState(prev => ({
      ...prev,
      comments: [...prev.comments, comment],
    }));
    setDisplayCommentOptions(false);
    setNewComment({ ...newComment, comment: '', attachments: [] });
  };

  return (
    <>
      <Modal show={true} onHide={onHide} size="xl" className="lato" centered>
        {(updatingTicket || loading) && (
          <div className={classNames(css(styles.overlay))}>
            <LoadingSpinner />
          </div>
        )}
        <Modal.Header closeButton>
          <div className={classNames(css(styles.headerContent))}>
            <Modal.Title>
              <strong>FSA - {ticket?.id}</strong>
            </Modal.Title>
            <OverlayTrigger overlay={<Tooltip>Copy ticket URL to clipboard</Tooltip>}>
              <FontAwesomeIcon
                icon={faCopy}
                className={classNames(pointerOnHover, 'ml-3')}
                onClick={() =>
                  handleCopyToClipboard(`${window.location.origin}/support-tickets/${ticket?.id}`)
                }
              />
            </OverlayTrigger>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className={classNames(css(styles.leftCol))}>
              <Row className={classNames(css(styles.task))}>
                <strong>{ticket?.task}</strong>
              </Row>
              <Row className={classNames('ml-2', css(styles.descriptionRow))}>
                <strong className={classNames(css(styles.subSectionsTitle), 'text-muted')}>
                  Description
                </strong>
                <div className={classNames(css(styles.subSectionDetails))}>
                  {ticket?.details.description.split('\n').map((line, index) => (
                    <div key={index}>{line}</div>
                  ))}
                </div>
              </Row>
              {images.length > 0 ? (
                <Row className="ml-2">
                  <Button variant="outline-primary" onClick={() => setShowAttachmentsModal(true)}>
                    View {images.length} Attachments
                  </Button>
                </Row>
              ) : null}
            </Col>
            <Col>
              <div>
                <strong className={classNames(css(styles.subSectionsTitle), 'text-muted')}>
                  SKU
                </strong>
                <OverlayTrigger overlay={<Tooltip>Copy SKU to clipboard</Tooltip>}>
                  <FontAwesomeIcon
                    icon={faCopy}
                    className={classNames(pointerOnHover, 'ml-2')}
                    onClick={() => handleCopyToClipboard(sku)}
                  />
                </OverlayTrigger>
              </div>
              <div
                className={classNames(css(styles.clickableText))}
                onClick={() => navigateToPDP()}
              >
                {sku}
              </div>
              <strong className={classNames(css(styles.subSectionsTitle), 'text-muted')}>
                Item Name
              </strong>
              <div className={classNames(css(styles.subSectionDetails))}>
                {ticket?.vendor_product
                  ? ticket?.vendor_product.name
                  : ticket?.manufacturer_product.name}
              </div>
              <strong className={classNames(css(styles.subSectionsTitle), 'text-muted')}>
                Product entity
                {ticket?.global_vendor
                  ? ` - ${ProductTypeDisplay.DISTRIBUTOR}`
                  : ` - ${ProductTypeDisplay.MANUFACTURER}`}
              </strong>
              <div className={classNames(css(styles.subSectionDetails))}>
                {ticket?.global_vendor
                  ? ticket?.global_vendor.name
                  : ticket?.global_manufacturer.name}
              </div>
              <strong className={classNames(css(styles.subSectionsTitle), 'text-muted')}>
                Reported By - {SupportTicketOriginLabels[ticket?.origin]}
              </strong>
              <div className={classNames(css(styles.subSectionDetails))}>{ticket?.reported_by}</div>
              <Form.Group>
                <Form.Label className={classNames(css(styles.subSectionsTitle), 'text-muted')}>
                  Assigned To
                </Form.Label>
                <SearchableScrollableSelect
                  initOption={updatedState.assignedTo}
                  onChange={option =>
                    setUpdatedState(prevState => ({ ...prevState, assignedTo: option || null }))
                  }
                  fetchUrl={USERS_GET_FOR_DROPDOWN}
                  optionMapper={assignedTo => ({ label: assignedTo.name, value: assignedTo.id })}
                  placeholder="Select assignedTo"
                  autoFocus={false}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className={classNames(css(styles.subSectionsTitle), 'text-muted')}>
                  Status
                </Form.Label>
                <Select
                  className={classNames(css(styles.subSectionDetails))}
                  value={
                    SupportTicketStatusOptions.find(
                      option => option.value === updatedState.status,
                    ) || null
                  }
                  options={SupportTicketStatusOptions}
                  onChange={option =>
                    setUpdatedState(prevState => ({ ...prevState, status: option.value }))
                  }
                />
              </Form.Group>
              {ticket?.callback_url ? (
                <>
                  <strong className={classNames(css(styles.subSectionsTitle), 'text-muted')}>
                    Comments
                  </strong>
                  <Form.Group>
                    <Form.Control
                      as="textarea"
                      rows={2}
                      value={newComment.comment}
                      placeholder="Add your comment here"
                      onChange={e => {
                        setNewComment(prev => ({ ...prev, comment: e.target.value }));
                      }}
                      className="mb-3"
                      style={{ overflowY: 'auto' }}
                      onFocus={() => setDisplayCommentOptions(true)}
                    />
                  </Form.Group>
                  {displayCommentOptions && (
                    <>
                      <Button className="mr-2 mb-2" onClick={() => handleAddNewComment()}>
                        Add new comment
                      </Button>
                      <Button
                        onClick={() => {
                          setDisplayCommentOptions(false);
                          setNewComment({ ...newComment, comment: '', attachments: [] });
                        }}
                        className="mr-2 mb-2"
                      >
                        Cancel
                      </Button>
                      <DropZone
                        text="Drop your file here to upload or click to browse"
                        onUploadSuccess={newUrls =>
                          setNewComment({ ...newComment, attachments: newUrls })
                        }
                        fileUrls={newComment.attachments}
                        isImage={true}
                        isPublic={true}
                        isMulti={true}
                        isDownloadable={true}
                      />
                    </>
                  )}
                  <div className={classNames(css(styles.commentSection), 'mt-2')}>
                    {updatedState.comments
                      .slice()
                      .reverse()
                      .map((comment, index) => (
                        <SupportTicketCommentBox
                          key={index}
                          comment={comment}
                          origin={ticket?.origin}
                        />
                      ))}
                  </div>
                </>
              ) : null}
              <Button variant="primary" onClick={handleSaveChanges} disabled={!hasChanges}>
                Save
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      {showAttachmentsModal && (
        <ImagesDisplayModal
          images={images}
          onHide={() => setShowAttachmentsModal(false)}
          origin={ticket?.origin}
        />
      )}
    </>
  );
}

const styles = StyleSheet.create({
  task: {
    fontSize: '1.8rem',
    marginBottom: '1.2rem',
    borderBottom: '3px solid ' + backgroundGrey4,
  },
  subSectionsTitle: {
    fontSize: '1.2rem',
    marginBottom: '1.2rem',
  },
  subSectionDetails: {
    marginBottom: '1rem',
  },
  leftCol: {
    paddingLeft: '1.9rem',
    paddingRight: '1rem',
    borderRight: '3px solid ' + backgroundGrey4,
  },
  descriptionRow: {
    display: 'flex',
    flexDirection: 'column',
  },
  clickableText: {
    color: 'blue',
    textDecoration: 'underline',
    cursor: 'pointer',
    marginBottom: '1rem',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    zIndex: 1050,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerContent: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  commentThread: {
    marginBottom: '1.5rem',
  },
  // commentBox: {
  //   border: `1px solid ${backgroundGrey4}`,
  //   borderRadius: '4px',
  //   padding: '10px',
  //   marginBottom: '10px',
  //   backgroundColor: '#f9f9f9',
  // },
  viewAttachmentsBtn: {
    padding: 0,
    marginTop: '5px',
  },
  commentSection: {
    maxHeight: '300px',
    overflowY: 'auto',
    padding: '10px',
    border: '1px solid #ddd', // Optional border for better visibility
    borderRadius: '4px', // Rounded edges
    backgroundColor: '#f9f9f9', // Background color for contrast
    marginBottom: '1.5rem', // Space below the section
  },
  commentBox: {
    borderBottom: '1px solid #ddd', // Separate comments visually
    paddingBottom: '10px',
    marginBottom: '10px',
  },
});

SupportTicketsModal.propTypes = {
  ticketId: PropTypes.number,
  onHide: PropTypes.func,
  refetch: PropTypes.func,
};

export default SupportTicketsModal;
