// update along with app/lib/enums/mpn_status.py
const MPNStatus = {
  MPN_AVAILABLE: 'MPN_AVAILABLE',
  MPN_NOT_AVAILABLE: 'MPN_NOT_AVAILABLE',
  MPN_NOT_VERIFIED: 'MPN_NOT_VERIFIED',
  MPN_ERROR: 'MPN_ERROR',
};

const MPNStatusOptions = [
  { value: MPNStatus.MPN_AVAILABLE, label: 'MPN Available' },
  { value: MPNStatus.MPN_NOT_AVAILABLE, label: 'MPN Not Available' },
  { value: MPNStatus.MPN_NOT_VERIFIED, label: 'MPN Not Verified' },
  { value: MPNStatus.MPN_ERROR, label: 'MPN Error' },
];

export { MPNStatus, MPNStatusOptions };
