import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments } from '@fortawesome/free-solid-svg-icons';

import { css, StyleSheet } from 'aphrodite';
import pointerOnHover from 'lib/css/pointerOnHover';
import { SupportTicketOriginLabels, SupportTicketStatusLabels } from 'lib/enums/SupportTicketEnums';

function calculateAge(dateString) {
  const createdDate = new Date(dateString);
  const now = new Date();
  const diffMs = now - createdDate;
  const diffMinutes = Math.floor(diffMs / (1000 * 60));

  if (diffMinutes < 60) {
    return `${diffMinutes}m`;
  }
  const diffHours = Math.floor(diffMinutes / 60);
  if (diffHours < 24) {
    return `${diffHours}h`;
  }
  const diffDays = Math.floor(diffHours / 24);
  if (diffDays < 7) {
    return `${diffDays}d`;
  }
  const diffWeeks = Math.floor(diffDays / 7);
  if (diffWeeks < 5) {
    return `${diffWeeks}w`;
  }
  const diffMonths = Math.floor(diffDays / 30);
  if (diffMonths < 12) {
    return `${diffMonths}mo`;
  }
  const diffYears = Math.floor(diffMonths / 12);
  return `${diffYears}year${diffYears > 1 ? 's' : ''}`;
}
function SupportTicketsTableRow({ ticket, onClick }) {
  return (
    <>
      <tr onClick={onClick} className={pointerOnHover}>
        <td className={css(styles.idColumn)}>{<strong>FSA - {ticket.id} </strong>}</td>
        <td style={{ position: 'relative' }}>
          <span>{ticket.task}</span>
          {ticket.comments?.length > 0 && (
            <span
              style={{
                position: 'absolute',
                right: '10px',
                top: '50%',
                transform: 'translateY(-50%)',
                color: '#666',
                display: 'flex',
                alignItems: 'center',
                gap: '4px',
              }}
            >
              <FontAwesomeIcon
                icon={faComments}
                style={{
                  color: '#666',
                }}
              />
              <span style={{ fontSize: '1.1em' }}>{ticket.comments.length}</span>
            </span>
          )}
        </td>
        <td>{SupportTicketOriginLabels[ticket.origin]}</td>
        <td>{ticket.global_vendor ? 'Distributor' : 'Manufacturer'}</td>
        <td>
          {ticket.global_vendor ? ticket.global_vendor.name : ticket.global_manufacturer.name}
        </td>
        <td>{SupportTicketStatusLabels[ticket.status]}</td>
        <td>{ticket.assigned_to ? ticket.assigned_to.name : '-'}</td>
        <td>{calculateAge(ticket.date_created)}</td>
      </tr>
    </>
  );
}

const styles = StyleSheet.create({
  idColumn: {
    minWidth: '100px',
  },
});

SupportTicketsTableRow.propTypes = {
  ticket: PropTypes.object,
  onClick: PropTypes.func,
};

export default SupportTicketsTableRow;
