import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';

import ImagesDisplayModal from 'components/settings/support_tickets/ImagesDisplayModal';

function SupportTicketCommentBox({ comment, origin }) {
  const [showCommentsAttachmentsModal, setShowCommentsAttachmentsModal] = useState(false);

  const formatDate = isoString => {
    const date = new Date(isoString);
    return date
      .toLocaleString('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,
      })
      .replace(',', ' at');
  };

  return (
    <div className={classNames(css(styles.commentBox))}>
      <strong>{comment.name}</strong>
      <span className="text-muted ml-2">{formatDate(comment.date)}</span>
      <div>{comment.comment}</div>
      {comment.attachments.length > 0 && (
        <>
          <Button
            variant="link"
            className={classNames(css(styles.viewAttachmentsBtn))}
            onClick={() => setShowCommentsAttachmentsModal(true)}
          >
            View {comment.attachments.length} Attachments
          </Button>
          {showCommentsAttachmentsModal && (
            <ImagesDisplayModal
              images={comment.attachments.map(imageUrl => ({
                url: imageUrl,
              }))}
              onHide={() => setShowCommentsAttachmentsModal(false)}
              origin={origin}
            />
          )}
        </>
      )}
    </div>
  );
}

const styles = StyleSheet.create({
  clickableText: {
    color: 'blue',
    textDecoration: 'underline',
    cursor: 'pointer',
    marginBottom: '1rem',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    zIndex: 1050,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerContent: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  commentThread: {
    marginBottom: '1.5rem',
  },
  viewAttachmentsBtn: {
    padding: 0,
    marginTop: '5px',
  },
  commentSection: {
    maxHeight: '300px',
    overflowY: 'auto',
    padding: '10px',
    border: '1px solid #ddd', // Optional border for better visibility
    borderRadius: '4px', // Rounded edges
    backgroundColor: '#f9f9f9', // Background color for contrast
    marginBottom: '1.5rem', // Space below the section
  },
  commentBox: {
    borderBottom: '1px solid #ddd', // Separate comments visually
    paddingBottom: '10px',
    marginBottom: '10px',
  },
});

SupportTicketCommentBox.propTypes = {
  comment: PropTypes.object,
  origin: PropTypes.string,
};

export default SupportTicketCommentBox;
