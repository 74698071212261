import React from 'react';
import PropTypes from 'prop-types';

import { Col, Row, Spinner } from 'react-bootstrap';

import LoadingSpinner from 'components/shared/LoadingSpinner';
import PaginationComponent from 'components/shared/PaginationComponent';
import ManufacturerPortalProductsTableView from 'components/manufacturer_portals/ManufacturerPortalProductsTableView';

function ManufacturerPortalCreateRequestProducts({
  products,
  productCount,
  itemsPerPage,
  setItemsPerPage,
  currentPage,
  setCurrentPage,
  loading,
  loadingCount,
}) {
  return (
    <div>
      <div className="my-3">
        <div>
          <Row>
            <Col xs={5} className="d-flex align-items-center justify-content-start">
              <div>
                {loadingCount ? (
                  <Spinner animation="border" role="status" size="sm" className="mr-4" />
                ) : (
                  `${productCount} products found`
                )}
              </div>
            </Col>
            <Col className="d-flex justify-content-end">
              <div className="d-flex align-items-center">
                {loadingCount ? (
                  <Spinner animation="border" role="status" size="sm" className="mr-4" />
                ) : (
                  <PaginationComponent
                    totalItems={productCount}
                    itemsPerPage={itemsPerPage}
                    setItemsPerPage={setItemsPerPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    defaultSize
                  />
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {loading ? <LoadingSpinner /> : <ManufacturerPortalProductsTableView products={products} />}
    </div>
  );
}

ManufacturerPortalCreateRequestProducts.propTypes = {
  products: PropTypes.array,
  productCount: PropTypes.number,
  itemsPerPage: PropTypes.number,
  setItemsPerPage: PropTypes.func,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  loading: PropTypes.bool,
  loadingCount: PropTypes.bool,
};

export default ManufacturerPortalCreateRequestProducts;
