import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { faPoo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { truncate } from 'lodash';

import { anchovy, bodyText, colorStyles } from 'lib/css/colors';
import placeholderProductImage from 'images/placeholder-img-product-v2.svg';
import pointerOnHover from 'lib/css/pointerOnHover';
import { ProductType } from 'lib/enums/ProductType';
import { titleCase } from 'lib/utils/StringProcess';
import UPBScoreBadge from 'components/upb_viewer/UPBScoreBadge';
import UserPermission from 'lib/enums/UserPermission';
import useUserPermissions from 'lib/hooks/useUserPermissions';

const DISPLAY_NAME_LENGTH = 45;

1890 * 2;

function UPBProductCatalogCard({ product, productType }) {
  const productLink = `/upb-pdp/${product.id}/${productType}`;
  const packSizeAndSku = [product?.propagated_pack_size].filter(Boolean).join(' | ');
  const hasUserPermissions = useUserPermissions();
  const viewIcon = useMemo(
    () => hasUserPermissions([UserPermission.UPB_VIEW_ADMIN]),
    [hasUserPermissions],
  );

  const productImageUrl =
    product.propagated_thumbnail_image_url ?? product.default_image_url ?? placeholderProductImage;

  return (
    <Card className={classNames('position-relative px-3 py-3', css(styles.card))}>
      <div className="d-flex justify-content-between">
        <div className="flex-grow-0">
          {viewIcon && productImageUrl.includes('google-scraped-global-product-default-images') && (
            <FontAwesomeIcon icon={faPoo} className={css(styles.editIcon)} />
          )}
        </div>
        <div className="">
          <UPBScoreBadge
            score={product.product_score?.percentage || product.cd_product_score?.percentage}
          />
        </div>
      </div>
      <div className={classNames(css(styles.cardContent), 'd-flex flex-column h-100')}>
        <div
          className={classNames(
            css(styles.productImageContainer),
            'align-items-center justify-content-center d-flex',
          )}
        >
          <LinkContainer className={pointerOnHover} to={productLink}>
            <Card.Img className={css(styles.productImage)} variant="top" src={productImageUrl} />
          </LinkContainer>
        </div>
        <Card.Body className="px-0 pt-0 pb-1 d-flex flex-column">
          <Link to={productLink} target="_blank" className={css(styles.displayName)}>
            <div className="text-capitalize mt-1">
              {titleCase(truncate(product.name, { length: DISPLAY_NAME_LENGTH, omission: '' }))}
              {product.name.length > DISPLAY_NAME_LENGTH && (
                <OverlayTrigger overlay={<Tooltip>{titleCase(product.name)}</Tooltip>}>
                  <span>...</span>
                </OverlayTrigger>
              )}
            </div>
          </Link>
          <div className={classNames(css(colorStyles.anchovy, styles.subText), 'text-nowrap mt-1')}>
            {product.brand && (
              <div className={classNames(css(styles.brandName))}>
                {product.brand.name}
                {product.brand.global_manufacturer && (
                  <span className="ml-1">by {product.brand.global_manufacturer.name}</span>
                )}
              </div>
            )}
            {<div>{packSizeAndSku}</div>}
            {product.gtin && <div>#{product.gtin}</div>}
            {product.id && <div>#{product.id}</div>}
          </div>
        </Card.Body>
      </div>
    </Card>
  );
}

const styles = StyleSheet.create({
  card: {
    minWidth: 180,
    maxWidth: 180,
    minHeight: 310,
    maxHeight: 320,
    borderRadius: 5,
    margin: '0px 10px 20px 10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
    transition: 'all 0.3s ease 0s',
    ':hover': {
      boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
      border: '1px solid ' + anchovy,
    },
  },
  cardContent: {
    height: '100%',
  },
  productImageContainer: {
    position: 'relative',
    width: '100%',
    height: 150,
    overflow: 'hidden',
  },
  subText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '13px',
    fontWeight: 600,
  },
  productImage: {
    ':hover': {
      opacity: 0.6,
    },
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
  },
  displayName: {
    textDecoration: 'none',
    fontSize: 15,
    fontWeight: 600,
    lineHeight: '24px',
    color: bodyText,
  },
  brandName: {
    lineHeight: '20px',
    textDecoration: 'underline',
  },
  editIcon: {
    fontSize: '14px',
    color: anchovy,
    ':hover': {
      opacity: 0.7,
      cursor: 'pointer',
    },
  },
});

UPBProductCatalogCard.propTypes = {
  product: PropTypes.object,
  productType: PropTypes.string,
};

export default UPBProductCatalogCard;
