import React from 'react';
import PropTypes from 'prop-types';

import { Badge } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import classNames from 'classnames';

import { blue1, lightGreen4, purple1, red, orange1 } from 'lib/css/colors';

function UPBScoreBadge({ score }) {
  if (!score) {
    return null;
  }
  const badgeColor =
    score >= 100
      ? lightGreen4
      : score >= 90
      ? blue1
      : score >= 80
      ? purple1
      : score >= 70
      ? orange1
      : red;

  const styles = StyleSheet.create({
    badge: {
      borderRadius: 9,
      fontSize: '0.80rem',
      backgroundColor: badgeColor,
    },
  });
  return (
    <Badge variant="secondary" className={classNames(css(styles.badge), 'px-2')}>
      {score}
    </Badge>
  );
}

UPBScoreBadge.propTypes = {
  score: PropTypes.number,
};

export default UPBScoreBadge;
