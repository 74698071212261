import React, { useState } from 'react';

import classNames from 'classnames';
import { Col, Row } from 'react-bootstrap';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select/creatable';
import { useHistory, useParams } from 'react-router-dom';

import Container from 'components/shared/Container';
import { DEFAULT_PAGE_SIZE } from 'lib/constants';
import {
  getURL,
  GLOBAL_VENDOR_MANUFACTURER_PORTAL_CREATE_REQUEST,
  GLOBAL_PRODUCT_VENDOR_GET_PRODUCTS,
} from 'lib/networking/endpoints';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import ManufacturerPortalCreateRequestProducts from 'components/manufacturer_portals/ManufacturerPortalCreateRequestProducts';
import { MPNStatusOptions } from 'lib/enums/MPNStatus';
import pointerOnHover from 'lib/css/pointerOnHover';
import useGet from 'lib/hooks/useGet';

function ManufacturerPortalCreateRequestView() {
  const { requestId } = useParams();
  const history = useHistory();

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(DEFAULT_PAGE_SIZE);
  const [selectedMPNStatus, setSelectedMPNStatus] = useState();

  const {
    data: { portal_request: portalRequest } = {},
    loading: loadingRequest,
    refetch: refetchRequest,
  } = useGet(
    getURL(GLOBAL_VENDOR_MANUFACTURER_PORTAL_CREATE_REQUEST, {
      request_id: requestId,
    }),
    {},
    !requestId,
  );

  const {
    data: { count: productCount } = {},
    loading: loadingCount,
    refetch: refetchCount,
  } = useGet(
    GLOBAL_PRODUCT_VENDOR_GET_PRODUCTS,
    {
      count_only: true,
      request_id: requestId,
      mpn_status: selectedMPNStatus?.value,
    },
    !requestId,
  );

  const {
    data: { products = [] } = {},
    loading,
    error,
    refetch,
  } = useGet(
    GLOBAL_PRODUCT_VENDOR_GET_PRODUCTS,
    {
      limit: itemsPerPage,
      offset: itemsPerPage * (currentPage - 1),
      request_id: requestId,
      mpn_status: selectedMPNStatus?.value,
    },
    !requestId,
  );

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return null;
  }

  return (
    <Container>
      <h3 className="font-weight-bold">
        <Row className="my-3">
          <Col className="ml-2">
            <FontAwesomeIcon
              className={classNames(pointerOnHover, 'mt-1 mr-2 float-left')}
              icon={faArrowCircleLeft}
              onClick={() => {
                history.goBack();
              }}
            />
            {portalRequest?.global_manufacturer?.name}
          </Col>
        </Row>
      </h3>
      <Row className="my-5 ml-2">
        <Col className="mr-3">
          <strong>Portal Created</strong>
          <div>
            {portalRequest?.global_manufacturer?.cnd_id
              ? `Yes (${portalRequest.global_manufacturer.cnd_id})`
              : 'No'}
          </div>
        </Col>
        <Col className="mr-3">
          <strong>Contact Name</strong>
          <div>{portalRequest?.contact_person_name}</div>
        </Col>
        <Col className="mr-3">
          <strong>Contact Email</strong>
          <div>{portalRequest?.contact_person_email}</div>
        </Col>
        <Col className="mr-3">
          <strong>MPN Status</strong>
          <Select
            placeholder="Select MPN Status"
            isClearable
            options={MPNStatusOptions}
            onChange={option => setSelectedMPNStatus(option)}
            value={selectedMPNStatus}
          />
        </Col>
        <Col className="mr-3">
          <strong>Distributor</strong>
          <div>{portalRequest?.global_vendor?.name}</div>
        </Col>
      </Row>
      <Row>
        <Col>
          <ManufacturerPortalCreateRequestProducts
            products={products}
            productCount={productCount}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            loading={loading}
            loadingCount={loadingCount}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default ManufacturerPortalCreateRequestView;
