import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { useDebounce } from 'use-debounce';

import CheckboxFiltersGroup from 'components/upb_viewer/CheckboxFiltersGroup';
import { GLOBAL_MANUFACTURERS_FOR_DROPDOWN, getURL } from 'lib/networking/endpoints';
import useGet from 'lib/hooks/useGet';

const LOAD_LIMIT = 50;

function ManufacturerFilterGroup({ selectedManufacturers, setSelectedManufacturers }) {
  const [searchText, setSearchText] = useState('');
  const [debouncedQuery] = useDebounce(searchText, 600);
  const { data: { data: manufacturers = [] } = {}, loading } = useGet(
    getURL(GLOBAL_MANUFACTURERS_FOR_DROPDOWN, {}),
    {
      search: debouncedQuery,
      limit: LOAD_LIMIT,
      content_verified_only: true,
    },
  );

  const manufacturerOptions = useMemo(() => {
    if (!manufacturers?.length) {
      return [];
    }
    const manufacturerOptions = manufacturers
      .map(manufacturer => ({
        label: manufacturer.name,
        value: manufacturer.id,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    // append selected manufacturers to the top of the list
    const selectedManufacturerIds = selectedManufacturers.map(manufacturer => manufacturer.value);
    const selectedManufacturerOptions = manufacturerOptions.filter(option =>
      selectedManufacturerIds.includes(option.value),
    );
    const unselectedManufacturerOptions = manufacturerOptions.filter(
      option => !selectedManufacturerIds.includes(option.value),
    );
    manufacturerOptions.length = 0;
    manufacturerOptions.push(...selectedManufacturerOptions, ...unselectedManufacturerOptions);
    return manufacturerOptions;
  }, [manufacturers, selectedManufacturers]);

  return (
    <CheckboxFiltersGroup
      options={manufacturerOptions}
      selectedOptions={selectedManufacturers}
      setSelectedOptions={setSelectedManufacturers}
      searchText={searchText}
      setSearchText={setSearchText}
      searchable
      placeholder={'Manufacturers...'}
      isLoading={loading}
    />
  );
}

ManufacturerFilterGroup.propTypes = {
  selectedManufacturers: PropTypes.arrayOf(PropTypes.object),
  setSelectedManufacturers: PropTypes.func.isRequired,
};

export default ManufacturerFilterGroup;
