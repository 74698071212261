import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import Swal from 'sweetalert2';
import ToggleSwitch from 'react-switch';

import Container from 'components/shared/Container';
import DataSourceEditTabFlagUpdateImportTypeOptions from 'components/data_sources/single_data_source_view/DataSourceEditTab/DataSourceEditTabFlagUpdateImportTypeOptions';
import DATA_SOURCE_CATEGORY, { LOOK_BACK_DAYS_REQUIRED_TYPES } from 'lib/enums/DataSourceCategory';
import { DATA_SOURCE_UPDATE } from 'lib/networking/endpoints';
import DATA_SOURCE_ENTITY, {
  DATA_SOURCE_IMPORT_TYPES,
  DATA_SOURCE_VERIFICATION_OPTIONS,
} from 'lib/enums/DataSourceEntity';
import DropZone from 'components/shared/Dropzone';
import {
  DATA_SOURCE_FILE_DROP_BUCKET,
  FILE_DROP_DEV_S3_LOCATION,
  FILE_DROP_PROD_S3_LOCATION,
} from 'lib/constants';
import { grape } from 'lib/css/colors';
import { isProd } from 'lib/networking/endpoints';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import TimeSelectorDropdown from 'components/shared/TimeSelectorDropdown';
import usePut from 'lib/hooks/usePut';
import UserPermission from 'lib/enums/UserPermission';
import useUserPermissions from 'lib/hooks/useUserPermissions';

const IS_RECURRING_OPTIONS = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

const OVERRIDE_EMPTY_VALUES_OPTIONS = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

function DataSourceEditTab({ dataSource, refetch }) {
  const hasUserPermissions = useUserPermissions();
  const [S3Url, setS3Url] = useState('');
  const [metaData, setMetaData] = useState({
    prefix: dataSource.prefix || '',
    suffix: dataSource.suffix || '',
    verify_content_status: dataSource.verify_content_status || null,
  });
  const [file, setFile] = useState('');
  const [lookbackDays, setLookbackDays] = useState(0);
  const [isRecurring, setIsRecurring] = useState({ label: 'No', value: false });
  const [considerLatestFile, setConsiderLatestFile] = useState(false);
  const [automaticTriggerOnFileDrop, setAutomaticTriggerOnFileDrop] = useState(false);
  const [overrideWithEmptyValues, setOverrideWithEmptyValues] = useState(false);
  const [delimiter, setDelimiter] = useState(dataSource.delimiter || ',');
  const [hasCopyright, setHasCopyright] = useState(dataSource.has_copyright || false);
  const [forceOverride, setForceOverride] = useState(dataSource.force_override || false);
  const [lockData, setLockData] = useState(dataSource.lock_data || false);
  const [rateExpression, setRateExpression] = useState({ hour: '', minute: '' });

  const [enrichManufacturerProduct, setEnrichManufacturerProduct] = useState(
    dataSource.enrich_manufacturer_product || false,
  );
  const [updateDistributorProductName, setUpdateDistributorProductName] = useState(
    dataSource.update_distributor_product_name || false,
  );
  const [flushExistingData, setFlushExistingData] = useState(
    dataSource.flush_existing_data || false,
  );
  const [flushExistingImages, setFlushExistingImages] = useState(
    dataSource.flush_existing_images || false,
  );
  const [verificationStatus, setVerificationStatus] = useState(
    dataSource.verification_status || null,
  );
  const [considerAttributeValueMap, setConsiderAttributeValueMap] = useState(
    dataSource.consider_attribute_value_map || false,
  );
  const [shouldVerifyContent, setShouldVerifyContent] = useState(false);
  const [ignoreEntityType, setIgnoreEntityType] = useState(dataSource.ignore_entity_type || false);
  const [createNutritionalPanelUpload, setCreateNutritionalPanelUpload] = useState(
    dataSource.create_nutritional_panel_upload || false,
  );
  const [assignUPBSKUs, setAssignUPBSKUs] = useState(dataSource.assign_upb_skus || false);
  const [useUPBSKUs, setUseUPBSKUs] = useState(dataSource.use_upb_skus || false);
  const [callableLogic, setCallableLogic] = useState(dataSource.callable_logic);

  const { putData: updateDataSource, loading: updating } = usePut(DATA_SOURCE_UPDATE, () => {
    Swal.fire({
      icon: 'success',
      title: 'Success',
      text: 'Data source successfully updated',
    });
    refetch();
  });

  useEffect(() => {
    setS3Url(dataSource.source_location_url);
    setFile(dataSource.source_location_url);
    setLookbackDays(dataSource.lookback_days);
    setConsiderLatestFile(dataSource.consider_latest_file);
    setAutomaticTriggerOnFileDrop(dataSource.automatic_trigger_on_file_drop);
    setMetaData({
      prefix: dataSource.meta_data?.prefix,
      suffix: dataSource.meta_data?.suffix,
      verify_content_status: dataSource.meta_data?.verify_content_status,
    });
    setIsRecurring(IS_RECURRING_OPTIONS.find(op => op.value === dataSource.is_recurring));
    setOverrideWithEmptyValues(
      OVERRIDE_EMPTY_VALUES_OPTIONS.find(op => op.value === dataSource.override_with_empty_values),
    );
    setRateExpression(dataSource.get_hour_and_minute_from_recurring_interval);
    setCallableLogic(dataSource.callable_logic);
  }, [dataSource]);

  const fileUploadRequired = useMemo(() => {
    return (
      dataSource.source_type === DATA_SOURCE_CATEGORY.CSV ||
      dataSource.source_type === DATA_SOURCE_CATEGORY.GS1_CSV
    );
  }, [dataSource.source_type]);

  const lookBackDaysRequired = useMemo(() => {
    return LOOK_BACK_DAYS_REQUIRED_TYPES.includes(dataSource.source_type);
  }, [dataSource.source_type]);

  const callableLogicRequired = useMemo(() => {
    return (
      dataSource.source_type === DATA_SOURCE_CATEGORY.S3_FOLDER &&
      dataSource.import_type === DATA_SOURCE_IMPORT_TYPES.IMAGE_IMPORT
    );
  }, [dataSource.source_type, dataSource.import_type]);

  const delimiterRequired = useMemo(() => {
    return (
      dataSource.source_type === DATA_SOURCE_CATEGORY.CSV ||
      dataSource.source_type === DATA_SOURCE_CATEGORY.S3_CSV ||
      dataSource.source_type === DATA_SOURCE_CATEGORY.GS1_CSV ||
      (dataSource.source_type === DATA_SOURCE_CATEGORY.S3_FOLDER &&
        dataSource.import_type === DATA_SOURCE_IMPORT_TYPES.ATTRIBUTE_IMPORT)
    );
  }, [dataSource.source_type, dataSource.import_type]);

  const handleSave = () => {
    if (fileUploadRequired && !file) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Please upload a file',
      });
      return;
    }

    if (lookBackDaysRequired && (!lookbackDays || (lookbackDays && isNaN(lookbackDays)))) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Please enter a valid lookback days value',
      });
      return;
    }

    const fileDropLocation = isProd ? FILE_DROP_PROD_S3_LOCATION : FILE_DROP_DEV_S3_LOCATION;
    const isValidUrl =
      S3Url.includes(fileDropLocation) || S3Url.includes(DATA_SOURCE_FILE_DROP_BUCKET);

    if (automaticTriggerOnFileDrop && !isValidUrl) {
      Swal.fire({
        icon: 'error',
        title: 'Invalid File Location',
        text:
          `The file URL must point to either the file drop location` +
          `(${fileDropLocation}) or the data source bucket (${DATA_SOURCE_FILE_DROP_BUCKET}).`,
      });
      return;
    }

    const updatedData = {
      data_source_id: dataSource.id,
      source_location_url: fileUploadRequired ? file : S3Url,
      lookback_days: lookBackDaysRequired ? lookbackDays : null,
      is_recurring: isRecurring ? isRecurring.value : false,
      recurring_interval: rateExpression,
      override_with_empty_values: overrideWithEmptyValues ? overrideWithEmptyValues.value : false,
      delimiter: delimiter,
      has_copyright: hasCopyright,
      force_override: forceOverride,
      lock_data: lockData,
      flush_existing_data: flushExistingData,
      flush_existing_images: flushExistingImages,
      verification_status: verificationStatus,
      consider_attribute_value_map: considerAttributeValueMap,
      consider_latest_file: considerLatestFile,
      automatic_trigger_on_file_drop: automaticTriggerOnFileDrop,
      meta_data: metaData,
      should_verify_content: shouldVerifyContent,
      enrich_manufacturer_product: enrichManufacturerProduct,
      update_distributor_product_name: updateDistributorProductName,
      ignore_entity_type: ignoreEntityType,
      create_nutritional_panel_upload: createNutritionalPanelUpload,
      assign_upb_skus: assignUPBSKUs,
      use_upb_skus: useUPBSKUs,
      callable_logic: callableLogic,
    };
    const booleanFields = [
      'is_recurring',
      'has_copyright',
      'force_override',
      'lock_data',
      'flush_existing_data',
      'flush_existing_images',
      'consider_attribute_value_map',
      'consider_latest_file',
      'automatic_trigger_on_file_drop',
      'should_verify_content',
      'enrich_manufacturer_product',
      'update_distributor_product_name',
      'ignore_entity_type',
      'create_nutritional_panel_upload',
      'assign_upb_skus',
      'use_upb_skus',
    ];
    const nonEmptyData = Object.keys(updatedData).reduce((obj, key) => {
      if (booleanFields.includes(key) && updatedData[key] === false) {
        obj[key] = updatedData[key];
      } else if (key === 'override_with_empty_values' && updatedData[key] === false) {
        obj[key] = updatedData[key];
      } else if (updatedData[key]) {
        obj[key] = updatedData[key];
      }
      return obj;
    }, {});

    updateDataSource(nonEmptyData);
  };

  if (updating) {
    return <LoadingSpinner />;
  }

  return (
    <Container className={css(styles.container)}>
      <div className="mb-3 w-100 d-flex justify-content-end">
        <Button className="px-5" variant="outline-primary" disabled={updating} onClick={handleSave}>
          Save
        </Button>
      </div>
      <div className="w-50">
        {[
          DATA_SOURCE_CATEGORY.GS1_S3_XML,
          DATA_SOURCE_CATEGORY.JSON_FEED,
          DATA_SOURCE_CATEGORY.S3_CSV,
          DATA_SOURCE_CATEGORY.S3_FOLDER,
        ].includes(dataSource.source_type) ? (
          <>
            <Form.Group as={Row}>
              <Form.Label column>
                S3 URI
                <OverlayTrigger
                  overlay={
                    <Tooltip>
                      eg: s3://cut-dry-vendor-integration/hillcrest/dev/inbound/trade_item_xml/
                    </Tooltip>
                  }
                  placement="top"
                  delayShow={300}
                  delayHide={150}
                >
                  <FontAwesomeIcon icon={faInfoCircle} className="ml-2" />
                </OverlayTrigger>
              </Form.Label>
              <Col md={7}>
                <Form.Control type="text" value={S3Url} onChange={e => setS3Url(e.target.value)} />
              </Col>
            </Form.Group>
          </>
        ) : null}

        {[
          DATA_SOURCE_CATEGORY.GS1_S3_XML,
          DATA_SOURCE_CATEGORY.JSON_FEED,
          DATA_SOURCE_CATEGORY.S3_CSV,
          DATA_SOURCE_CATEGORY.S3_FOLDER,
          DATA_SOURCE_CATEGORY.GDSN,
        ].includes(dataSource.source_type) ? (
          <>
            <Form.Group as={Row}>
              <Form.Label column>is Recurring</Form.Label>
              <Col md={7}>
                <Select
                  value={isRecurring}
                  options={IS_RECURRING_OPTIONS}
                  onChange={e => setIsRecurring(e)}
                />
              </Col>
            </Form.Group>
            {isRecurring.value ? (
              <TimeSelectorDropdown
                rate={rateExpression}
                setHour={h => setRateExpression({ ...rateExpression, hour: h })}
                setMinute={m => setRateExpression({ ...rateExpression, minute: m })}
              />
            ) : null}
          </>
        ) : null}

        {dataSource.source_type === DATA_SOURCE_CATEGORY.S3_FOLDER &&
        dataSource.import_type === DATA_SOURCE_IMPORT_TYPES.ATTRIBUTE_IMPORT ? (
          <>
            <hr />
            {isRecurring.value ? (
              <Form.Group className="d-flex align-items-center" as={Row}>
                <Form.Label column>
                  Trigger on File Drop
                  <OverlayTrigger
                    overlay={
                      <Tooltip>
                        If turned on, when a file drop happens on the given s3 location the data
                        source will get triggered automatically
                      </Tooltip>
                    }
                    placement="top"
                    delayShow={300}
                    delayHide={150}
                  >
                    <FontAwesomeIcon icon={faInfoCircle} className="ml-2" />
                  </OverlayTrigger>
                </Form.Label>
                <Col md={8}>
                  <ToggleSwitch
                    checked={automaticTriggerOnFileDrop}
                    onColor={grape}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onChange={() => setAutomaticTriggerOnFileDrop(!automaticTriggerOnFileDrop)}
                    className="ml-2"
                  />
                </Col>
              </Form.Group>
            ) : null}

            {!automaticTriggerOnFileDrop && (
              <Form.Group className="d-flex align-items-center" as={Row}>
                <Form.Label column>
                  Use the latest file
                  <OverlayTrigger
                    overlay={
                      <Tooltip>
                        If turned on,the latest file on the given folder will be considered.
                      </Tooltip>
                    }
                    placement="top"
                    delayShow={300}
                    delayHide={150}
                  >
                    <FontAwesomeIcon icon={faInfoCircle} className="ml-2" />
                  </OverlayTrigger>
                </Form.Label>
                <Col md={7}>
                  <ToggleSwitch
                    checked={considerLatestFile}
                    onColor={grape}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onChange={() => setConsiderLatestFile(!considerLatestFile)}
                    className="ml-2"
                  />
                </Col>
              </Form.Group>
            )}

            <Form.Group as={Row}>
              <Form.Label column>
                Prefix
                <OverlayTrigger
                  overlay={
                    <Tooltip>
                      The starting prefix of the file, This is needed if `Use the latest file` is
                      turned on
                    </Tooltip>
                  }
                  placement="top"
                  delayShow={300}
                  delayHide={150}
                >
                  <FontAwesomeIcon icon={faInfoCircle} className="ml-2" />
                </OverlayTrigger>
              </Form.Label>
              <Col md={7}>
                <Form.Control
                  placeholder="canonical_products"
                  type="text"
                  value={metaData.prefix}
                  onChange={e => setMetaData({ ...metaData, prefix: e.target.value })}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column>
                Suffix
                <OverlayTrigger
                  overlay={
                    <Tooltip>
                      The suffix of the file, This is needed if `Use the latest file` is turned on
                    </Tooltip>
                  }
                  placement="top"
                  delayShow={300}
                  delayHide={150}
                >
                  <FontAwesomeIcon icon={faInfoCircle} className="ml-2" />
                </OverlayTrigger>
              </Form.Label>
              <Col md={7}>
                <Form.Control
                  placeholder=".csv"
                  type="text"
                  value={metaData.suffix}
                  onChange={e => setMetaData({ ...metaData, suffix: e.target.value })}
                />
              </Col>
            </Form.Group>
            <hr />
          </>
        ) : null}

        {fileUploadRequired ? (
          <Form.Group as={Row}>
            <Form.Label column>File</Form.Label>
            <Col md={7}>
              <div className="w-50">
                <div className="text-muted">{file}</div>
                <DropZone
                  text="Drag and drop some files here, or click to select files"
                  onUploadSuccess={newUrls => setFile(newUrls?.[0]?.s3_path)}
                  fileUrls={file ? [file.presigned_url] : []}
                  isImage={false}
                />
              </div>
            </Col>
          </Form.Group>
        ) : null}
        {lookBackDaysRequired ? (
          <Form.Group>
            <Form.Label>Lookback Days</Form.Label>
            <Form.Control
              type="text"
              value={lookbackDays}
              onChange={e => setLookbackDays(e.target.value)}
            />
          </Form.Group>
        ) : null}
        {/* 
          Callable logic to extract product SKU and thumbnail status from image names.
          Example format: lambda x: (x.split("_")[0].split("-")[0], "-" not in x.split("_")[0])
          - Returns tuple: (base_sku, is_thumbnail)
          - First element extracts SKU before first '-' in the filename prefix
          - Second element checks
            if filename prefix contains '-' (False = has variant/version, True = thumbnail)
          For 'PROD12345-5_9585766.jpg' would return ('PROD12345', False)
          For 'PROD67890.jpg' would return ('PROD67890', True)
        */}
        {callableLogicRequired ? (
          <Form.Group>
            <Form.Label>Callable Logic</Form.Label>
            <Form.Control
              type="text"
              value={callableLogic}
              onChange={e => setCallableLogic(e.target.value)}
              disabled={!hasUserPermissions([UserPermission.ADMIN_OPERATIONS])}
            />
          </Form.Group>
        ) : null}
        {delimiterRequired ? (
          <Form.Group as={Row}>
            <Form.Label column>Delimiter</Form.Label>
            <Col md={7}>
              <Form.Control
                type="text"
                value={delimiter}
                onChange={e => setDelimiter(e.target.value)}
              />
            </Col>
          </Form.Group>
        ) : null}

        {dataSource.import_type === DATA_SOURCE_IMPORT_TYPES.FLAG_UPDATE ? (
          <DataSourceEditTabFlagUpdateImportTypeOptions
            metaData={metaData}
            setMetaData={setMetaData}
          />
        ) : (
          <>
            <Form.Group as={Row}>
              <Form.Label column>Override with empty values</Form.Label>
              <Col md={7}>
                <Select
                  value={overrideWithEmptyValues}
                  options={OVERRIDE_EMPTY_VALUES_OPTIONS}
                  onChange={e => setOverrideWithEmptyValues(e)}
                />
              </Col>
            </Form.Group>
            {/* Verification status only applies for the manufacturer products as the moment. */}
            {dataSource.entity_type === DATA_SOURCE_ENTITY.MANUFACTURER ? (
              <Form.Group as={Row}>
                <Form.Label column>
                  Product Verification
                  <OverlayTrigger
                    overlay={<Tooltip>Whether to mark these products as verified or not?</Tooltip>}
                    placement="top"
                    delayShow={300}
                    delayHide={150}
                  >
                    <FontAwesomeIcon icon={faInfoCircle} className="ml-2" />
                  </OverlayTrigger>
                </Form.Label>
                <Col md={7}>
                  <Select
                    value={DATA_SOURCE_VERIFICATION_OPTIONS.find(
                      v => v.value === verificationStatus,
                    )}
                    options={DATA_SOURCE_VERIFICATION_OPTIONS}
                    onChange={e => setVerificationStatus(e?.value)}
                  />
                </Col>
              </Form.Group>
            ) : null}
            <Form.Group className="d-flex align-items-center" as={Row}>
              <Form.Label column>
                Copyright Available
                <OverlayTrigger
                  overlay={
                    <Tooltip>
                      C+D has the copyright of this data? Data will not be overridden unless
                      &quot;force override&quot; is turned on.
                    </Tooltip>
                  }
                >
                  <FontAwesomeIcon icon={faInfoCircle} className="ml-2" />
                </OverlayTrigger>
              </Form.Label>
              <Col md={7}>
                <ToggleSwitch
                  checked={hasCopyright}
                  onColor={grape}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onChange={() => setHasCopyright(!hasCopyright)}
                  className="ml-2"
                />
              </Col>
            </Form.Group>

            <Form.Group className="d-flex align-items-center" as={Row}>
              <Form.Label column>
                Force Override
                <OverlayTrigger
                  overlay={
                    <Tooltip>
                      If turned on, the data will be overridden even if it marked as has copyright.
                    </Tooltip>
                  }
                  placement="top"
                  delayShow={300}
                  delayHide={150}
                >
                  <FontAwesomeIcon icon={faInfoCircle} className="ml-2" />
                </OverlayTrigger>
              </Form.Label>
              <Col md={7}>
                <ToggleSwitch
                  checked={forceOverride}
                  onColor={grape}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onChange={() => setForceOverride(!forceOverride)}
                  className="ml-2"
                />
              </Col>
            </Form.Group>

            <Form.Group className="d-flex align-items-center" as={Row}>
              <Form.Label column>
                Lock Data
                <OverlayTrigger
                  overlay={
                    <Tooltip>
                      If turned on, the data will not be overridden by any other data source.(unless
                      force override is enabled).
                    </Tooltip>
                  }
                  placement="top"
                  delayShow={300}
                  delayHide={150}
                >
                  <FontAwesomeIcon icon={faInfoCircle} className="ml-2" />
                </OverlayTrigger>
              </Form.Label>
              <Col md={7}>
                <ToggleSwitch
                  checked={lockData}
                  onColor={grape}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onChange={() => setLockData(!lockData)}
                  className="ml-2"
                />
              </Col>
            </Form.Group>

            <Form.Group className="d-flex align-items-center" as={Row}>
              <Form.Label column>
                Flush Existing Data
                <OverlayTrigger
                  overlay={
                    <Tooltip>
                      If turned on, the existing data will be deleted before the new data is
                      inserted.
                    </Tooltip>
                  }
                  placement="top"
                  delayShow={300}
                  delayHide={150}
                >
                  <FontAwesomeIcon icon={faInfoCircle} className="ml-2" />
                </OverlayTrigger>
              </Form.Label>
              <Col md={7}>
                <ToggleSwitch
                  checked={flushExistingData}
                  onColor={grape}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onChange={() => setFlushExistingData(!flushExistingData)}
                  className="ml-2"
                />
              </Col>
            </Form.Group>

            <Form.Group className="d-flex align-items-center" as={Row}>
              <Form.Label column>
                Flush Existing Images
                <OverlayTrigger
                  overlay={
                    <Tooltip>
                      If turned on, the existing images(thumbnail and other_images) will be deleted
                      before the new data is inserted. If new data is not provided then the existing
                      images will not be deleted.
                    </Tooltip>
                  }
                  placement="top"
                  delayShow={300}
                  delayHide={150}
                >
                  <FontAwesomeIcon icon={faInfoCircle} className="ml-2" />
                </OverlayTrigger>
              </Form.Label>
              <Col md={7}>
                <ToggleSwitch
                  checked={flushExistingImages}
                  onColor={grape}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onChange={() => setFlushExistingImages(!flushExistingImages)}
                  className="ml-2"
                />
              </Col>
            </Form.Group>

            <Form.Group className="d-flex align-items-center" as={Row}>
              <Form.Label column>
                Consider Attribute Value Mapping
                <OverlayTrigger
                  overlay={
                    <Tooltip>
                      If turned on, then attribute value mapping will be used to replace the values.
                    </Tooltip>
                  }
                  placement="top"
                  delayShow={300}
                  delayHide={150}
                >
                  <FontAwesomeIcon icon={faInfoCircle} className="ml-2" />
                </OverlayTrigger>
              </Form.Label>
              <Col md={7}>
                <ToggleSwitch
                  checked={considerAttributeValueMap}
                  onColor={grape}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onChange={() => setConsiderAttributeValueMap(!considerAttributeValueMap)}
                  className="ml-2"
                />
              </Col>
            </Form.Group>

            <Form.Group className="d-flex align-items-center" as={Row}>
              <Form.Label column>
                Mark for content verification
                <OverlayTrigger
                  overlay={
                    <Tooltip>
                      If turned on, this data will go to the Scrapped content verification tool
                    </Tooltip>
                  }
                  placement="top"
                  delayShow={300}
                  delayHide={150}
                >
                  <FontAwesomeIcon icon={faInfoCircle} className="ml-2" />
                </OverlayTrigger>
              </Form.Label>
              <Col md={7}>
                <ToggleSwitch
                  checked={shouldVerifyContent}
                  onColor={grape}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onChange={() => setShouldVerifyContent(!shouldVerifyContent)}
                  className="ml-2"
                />
              </Col>
            </Form.Group>

            <Form.Group className="d-flex align-items-center" as={Row}>
              <Form.Label column>
                Update distributor Product Name if exists
                <OverlayTrigger
                  overlay={
                    <Tooltip>
                      If turned off, if a distributor product exists the name will not be updated.
                    </Tooltip>
                  }
                  placement="top"
                  delayShow={300}
                  delayHide={150}
                >
                  <FontAwesomeIcon icon={faInfoCircle} className="ml-2" />
                </OverlayTrigger>
              </Form.Label>
              <Col md={7}>
                <ToggleSwitch
                  checked={updateDistributorProductName}
                  onColor={grape}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onChange={() => setUpdateDistributorProductName(!updateDistributorProductName)}
                  className="ml-2"
                />
              </Col>
            </Form.Group>

            {[
              DATA_SOURCE_CATEGORY.GS1_S3_XML,
              DATA_SOURCE_CATEGORY.GS1_CSV,
              DATA_SOURCE_CATEGORY.CSV,
            ].includes(dataSource.source_type) &&
            DATA_SOURCE_IMPORT_TYPES.ATTRIBUTE_IMPORT === dataSource.import_type &&
            DATA_SOURCE_ENTITY.VENDOR === dataSource.entity_type ? (
              <Form.Group className="d-flex align-items-center" as={Row}>
                <Form.Label column>
                  Enrich Manufacturer Product
                  <OverlayTrigger
                    overlay={
                      <Tooltip>
                        If turned on, instead of the distributor product the manufactrer product
                        will be enriched.
                      </Tooltip>
                    }
                    placement="top"
                    delayShow={300}
                    delayHide={150}
                  >
                    <FontAwesomeIcon icon={faInfoCircle} className="ml-2" />
                  </OverlayTrigger>
                </Form.Label>
                <Col md={7}>
                  <ToggleSwitch
                    checked={enrichManufacturerProduct}
                    onColor={grape}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onChange={() => setEnrichManufacturerProduct(!enrichManufacturerProduct)}
                    className="ml-2"
                  />
                </Col>
              </Form.Group>
            ) : null}

            <Form.Group className="d-flex align-items-center" as={Row}>
              <Form.Label column>
                Ignore Product Entity Type
                <OverlayTrigger
                  overlay={
                    <Tooltip>
                      If turned on, can import both manufacturer and vendor data in a single import.
                    </Tooltip>
                  }
                  placement="top"
                  delayShow={300}
                  delayHide={150}
                >
                  <FontAwesomeIcon icon={faInfoCircle} className="ml-2" />
                </OverlayTrigger>
              </Form.Label>
              <Col md={7}>
                <ToggleSwitch
                  checked={ignoreEntityType}
                  onColor={grape}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onChange={() => setIgnoreEntityType(!ignoreEntityType)}
                  className="ml-2"
                />
              </Col>
            </Form.Group>
            <Form.Group className="d-flex align-items-center" as={Row}>
              <Form.Label column>
                Create nutritional panel upload
                <OverlayTrigger
                  overlay={
                    <Tooltip>
                      If turned on, will create a nutritional panel upload on every import
                    </Tooltip>
                  }
                  placement="top"
                  delayShow={300}
                  delayHide={150}
                >
                  <FontAwesomeIcon icon={faInfoCircle} className="ml-2" />
                </OverlayTrigger>
              </Form.Label>
              <Col md={7}>
                <ToggleSwitch
                  checked={createNutritionalPanelUpload}
                  onColor={grape}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onChange={() => setCreateNutritionalPanelUpload(!createNutritionalPanelUpload)}
                  className="ml-2"
                />
              </Col>
            </Form.Group>

            {dataSource.entity_type === DATA_SOURCE_ENTITY.MANUFACTURER && (
              <Form.Group className="d-flex align-items-center" as={Row}>
                <Form.Label column>
                  Assign UPB SKUs
                  <OverlayTrigger
                    overlay={
                      <Tooltip>If turned on, will assign UPB SKUs to products on the file.</Tooltip>
                    }
                    placement="top"
                    delayShow={300}
                    delayHide={150}
                  >
                    <FontAwesomeIcon icon={faInfoCircle} className="ml-2" />
                  </OverlayTrigger>
                </Form.Label>
                <Col md={7}>
                  <ToggleSwitch
                    checked={assignUPBSKUs}
                    onColor={grape}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onChange={() => setAssignUPBSKUs(!assignUPBSKUs)}
                    className="ml-2"
                  />
                </Col>
              </Form.Group>
            )}
            {dataSource.entity_type === DATA_SOURCE_ENTITY.VENDOR &&
              dataSource.enrich_manufacturer_product && (
                <Form.Group className="d-flex align-items-center" as={Row}>
                  <Form.Label column>
                    Use UPB SKUs for manufacturer products
                    <OverlayTrigger
                      overlay={
                        <Tooltip>
                          If turned on, will use UPB SKUs to create manufacturer products if
                          manufacturer_product_code is not there and if enrich manufacturer products
                          toggle is on.
                        </Tooltip>
                      }
                      placement="top"
                      delayShow={300}
                      delayHide={150}
                    >
                      <FontAwesomeIcon icon={faInfoCircle} className="ml-2" />
                    </OverlayTrigger>
                  </Form.Label>
                  <Col md={7}>
                    <ToggleSwitch
                      checked={useUPBSKUs}
                      onColor={grape}
                      checkedIcon={false}
                      uncheckedIcon={false}
                      onChange={() => setUseUPBSKUs(!useUPBSKUs)}
                      className="ml-2"
                    />
                  </Col>
                </Form.Group>
              )}
          </>
        )}
      </div>
    </Container>
  );
}

const styles = StyleSheet.create({
  container: {
    minHeight: '100vh',
    overflowX: 'hidden',
  },
});

DataSourceEditTab.propTypes = {
  dataSource: PropTypes.object,
  refetch: PropTypes.func,
};

export default DataSourceEditTab;
