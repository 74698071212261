import { BrandVerificationStatus } from 'lib/enums/BrandVerificationStatus';
import { ManufacturerVerificationStatus } from 'lib/enums/ManufacturerVerificationStatus';
// update along with app/lib/enums/dashboard_widget_types.py
const DashboardWidgetType = {
  VERIFIED_VENDOR_PRODUCTS: 'VERIFIED_VENDOR_PRODUCTS',
  VERIFIED_MANUFACTURER_PRODUCTS: 'VERIFIED_MANUFACTURER_PRODUCTS',
  VERIFIED_BRANDS: 'VERIFIED_BRANDS',
  VERIFIED_MANUFACTURERS: 'VERIFIED_MANUFACTURERS',
  TAGGED_IMAGES: 'TAGGED_IMAGES',
  CONTENT_VERIFIED_BRANDS: 'CONTENT_VERIFIED_BRANDS',
  VERIFICATION_PENDING_BRANDS: 'VERIFICATION_PENDING_BRANDS',
  VERIFICATION_PENDING_MANUFACTURERS: 'VERIFICATION_PENDING_MANUFACTURERS',
  UNVERIFIED_BRANDS_IN_DISTRIBUTOR_PARTNERS: 'UNVERIFIED_BRANDS_IN_DISTRIBUTOR_PARTNERS',
  UNCLASSIFIED_ACTIVE_DISTRIBUTOR_PARTNER_PRODUCTS:
    'UNCLASSIFIED_ACTIVE_DISTRIBUTOR_PARTNER_PRODUCTS',
  UNCLASSIFIED_VERIFIED_MFR_PRODUCTS: 'UNCLASSIFIED_VERIFIED_MFR_PRODUCTS',
  VERIFIED_SIMILAR_BRANDS: 'VERIFIED_SIMILAR_BRANDS',
  CONTENT_VERIFIED_MANUFACTURER_PRODUCTS: 'CONTENT_VERIFIED_MANUFACTURER_PRODUCTS',
  RESOLVED_BRANDS: 'RESOLVED_BRANDS',
};

const DashboardWidgetTypeLabels = {
  [DashboardWidgetType.VERIFIED_VENDOR_PRODUCTS]: 'Verified Vendor Products',
  [DashboardWidgetType.VERIFIED_MANUFACTURER_PRODUCTS]: 'Verified Manufacturer Products',
  [DashboardWidgetType.VERIFIED_BRANDS]: 'Verified Brands',
  [DashboardWidgetType.VERIFIED_MANUFACTURERS]: 'Verified Manufacturers',
  [DashboardWidgetType.TAGGED_IMAGES]: 'Tagged Images',
  [DashboardWidgetType.CONTENT_VERIFIED_BRANDS]: 'Content Verified Brands',
  [DashboardWidgetType.VERIFICATION_PENDING_BRANDS]: 'Verification Pending Brands',
  [DashboardWidgetType.VERIFICATION_PENDING_MANUFACTURERS]: 'Verification Pending Manufacturers',
  [DashboardWidgetType.UNVERIFIED_BRANDS_IN_DISTRIBUTOR_PARTNERS]:
    'Unverified Brands Among Distributor Partners',
  [DashboardWidgetType.UNCLASSIFIED_ACTIVE_DISTRIBUTOR_PARTNER_PRODUCTS]:
    'Unclassified Active DP Products',
  [DashboardWidgetType.UNCLASSIFIED_VERIFIED_MFR_PRODUCTS]:
    'Unclassified Verified Manufacturer Products',
  [DashboardWidgetType.VERIFIED_SIMILAR_BRANDS]: 'Verified Similar Brands',
  [DashboardWidgetType.CONTENT_VERIFIED_MANUFACTURER_PRODUCTS]:
    'Content Verified Manufacturer Products',
  [DashboardWidgetType.RESOLVED_BRANDS]: 'Resolved Brands',
};

const handleVerificationPendingBrandsClick = () => {
  const url =
    `/enrich-products/manage-brands` +
    `?verificationStatus=${BrandVerificationStatus.PENDING_VERIFICATION}`;
  window.open(url, '_blank');
};

const handleUnverifiedBrandsClick = () => {
  const url =
    `/enrich-products/manage-brands` +
    `?verificationStatus=${BrandVerificationStatus.UNVERIFIED}&isDPOnly=true`;
  window.open(url, '_blank');
};

const handleVerificationPendingManufacturersClick = () => {
  const url =
    `/enrich-products/manage-manufacturers` +
    `?verificationStatus=${ManufacturerVerificationStatus.PENDING_VERIFICATION}`;
  window.open(url, '_blank');
};

const WidgetClickHandlers = {
  [DashboardWidgetType.VERIFICATION_PENDING_BRANDS]: handleVerificationPendingBrandsClick,
  [DashboardWidgetType.VERIFICATION_PENDING_MANUFACTURERS]:
    handleVerificationPendingManufacturersClick,
  [DashboardWidgetType.UNVERIFIED_BRANDS_IN_DISTRIBUTOR_PARTNERS]: handleUnverifiedBrandsClick,
};

export { DashboardWidgetTypeLabels, WidgetClickHandlers };
export default DashboardWidgetType;
