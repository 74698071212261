import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Form, Modal } from 'react-bootstrap';
import Select from 'react-select';
import Swal from 'sweetalert2';

import {
  GLOBAL_MANUFACTURERS_FOR_DROPDOWN,
  GLOBAL_VENDORS_FOR_DROPDOWN,
  GLOBAL_VENDOR_MANUFACTURER_PORTAL_CREATE,
} from 'lib/networking/endpoints';
import SearchableScrollableSelect from 'components/shared/SearchableScrollableSelect';
import useGet from 'lib/hooks/useGet';
import usePost from 'lib/hooks/usePost';

const NUTRITIONAL_PANEL_FOLDER = 'nutritional_panels';

function CreateManufacturerPortalModal({ onHide, refetch }) {
  const [distributor, setDistributor] = useState({});
  const [manufacturer, setManufacturer] = useState({});
  const [contactName, setContactName] = useState('');
  const [contactEmail, setContactEmail] = useState('');

  const { postData: createManufacturerPortals, loading } = usePost(
    GLOBAL_VENDOR_MANUFACTURER_PORTAL_CREATE,
    () => {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Manufacturer portal request created successfully',
      }).then(() => onHide());
      refetch();
    },
    error =>
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text:
          `An error occurred while creating the manufacturer portal ` +
          `request: ${error?.response?.data?.message}`,
      }),
  );

  const { data: { data: globalVendors = [] } = {} } = useGet(GLOBAL_VENDORS_FOR_DROPDOWN);

  const distributorOptions = globalVendors.map(vendor => ({
    value: vendor.id,
    label: vendor.name,
  }));

  const onSubmit = () => {
    const data = {
      name: name,
      global_vendor_id: distributor?.value,
      global_manufacturer_id: manufacturer?.value,
      contact_person_name: contactName,
      contact_person_email: contactEmail,
    };
    createManufacturerPortals(data);
  };

  return (
    <Modal show={true} onHide={onHide} size="lg" className="px-6" centered animation={false}>
      <Modal.Header>
        <div className="d-flex flex-column">
          <Modal.Title className="mont">
            <h2 className="bold">Create Manufacturer Portals</h2>
          </Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Distributor Name</Form.Label>
          <Select
            value={distributor}
            options={distributorOptions}
            onChange={e => setDistributor(e)}
            isSearchable
            isClearable
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Manufacturer Name</Form.Label>
          <SearchableScrollableSelect
            onChange={option => setManufacturer(option)}
            fetchUrl={GLOBAL_MANUFACTURERS_FOR_DROPDOWN}
            optionMapper={manufacturer => ({
              label: manufacturer.name,
              value: manufacturer.id,
            })}
            autoFocus={false}
            placeholder="Select Manufacturer"
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Manufacturer Contact Name</Form.Label>
          <Form.Control
            type="text"
            value={contactName}
            onChange={e => setContactName(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Manufacturer Contact email</Form.Label>
          <Form.Control
            type="text"
            value={contactEmail}
            onChange={e => setContactEmail(e.target.value)}
          />
        </Form.Group>
        <div className="my-3">
          <Button className="px-5 mr-2" disabled={loading} onClick={onSubmit}>
            Create Portal Request
          </Button>
          <Button
            variant="outline-primary"
            onClick={() => {
              onHide();
            }}
          >
            Cancel
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

CreateManufacturerPortalModal.propTypes = {
  onHide: PropTypes.func,
  refetch: PropTypes.func,
};

export default CreateManufacturerPortalModal;
export { NUTRITIONAL_PANEL_FOLDER };
