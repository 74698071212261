//update along with app/lib/enums/support_ticket_enums.py
const SupportTicketStatus = {
  PENDING_REVIEW: 'PENDING_REVIEW',
  TO_DO: 'TO_DO',
  IN_PROGRESS: 'IN_PROGRESS',
  ON_HOLD: 'ON_HOLD',
  IN_QA: 'IN_QA',
  COMPLETED: 'COMPLETED',
  WONT_FIX: 'WONT_FIX',
};

const SupportTicketStatusLabels = {
  PENDING_REVIEW: 'Pending review',
  TO_DO: 'To do',
  IN_PROGRESS: 'In progress',
  ON_HOLD: 'On hold',
  IN_QA: 'In QA',
  COMPLETED: 'Completed',
  WONT_FIX: "Won't fix",
};

const SupportTicketStatusOptions = Object.entries(SupportTicketStatusLabels).map(
  ([value, label]) => ({
    label,
    value,
  }),
);

//update along with app/lib/enums/support_ticket_enums.py
const SupportTicketOrigins = {
  CND: 'CND',
  FSA: 'FSA',
  DISTRIBUTOR: 'DISTRIBUTOR',
  OPERATOR: 'OPERATOR',
};

const SupportTicketOriginLabels = {
  CND: 'C+D Internal User',
  FSA: 'FSA User',
  DISTRIBUTOR: 'Distributor',
  OPERATOR: 'Operator',
};

export {
  SupportTicketStatus,
  SupportTicketStatusLabels,
  SupportTicketStatusOptions,
  SupportTicketOrigins,
  SupportTicketOriginLabels,
};
