import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Badge, Image } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { auroMetalSaurus, darkGrey2, grey, white } from 'lib/css/colors';

const NEST_INDENTATION = 20; //px
const ROOT_INDENTATION = 10; //px

const createOptionNode = (value, label, subOptions, data) => ({
  ...(data || {}),
  value,
  label,
  subOptions,
});

const isLeafNode = option => !option.subOptions?.length;

/**
 * If for a node, there is only a single path to reach a leaf and all the labels are similar,
 * no point in showing the children as nested
 * @param option
 * @returns
 */
const shouldHideChildren = option => {
  if (isLeafNode(option)) {
    return true;
  }
  const { subOptions, label } = option;
  return (
    subOptions?.length === 1 &&
    subOptions[0].label.trim() === label.trim() &&
    shouldHideChildren(subOptions[0])
  );
};

const formatCurrency = value => {
  if (value === null || value === undefined) {
    return;
  }
  if (value >= 1000000) {
    return `$${(value / 1000000).toFixed(1)}M`;
  }
  if (value >= 1000) {
    return `$${(value / 1000).toFixed(1)}K`;
  }
  return `$${value.toFixed(2)}`;
};

const LeafMenuItem = ({
  option,
  selectedOption,
  setSelectedOptions,
  isRootNode = false,
  showCategoryBadges = false,
}) => {
  const isActive = option.value === selectedOption?.value;

  return (
    <div
      className={classNames(css(styles.node, isActive ? styles.activeLeaf : styles.inactiveLeaf))}
      onClick={() => setSelectedOptions([option])}
    >
      <span className="pl-4">{option.label}</span>
      {showCategoryBadges && (
        <Badge
          className={classNames(
            css(styles.categoryBadge, isActive ? styles.activeBadge : styles.InactiveBadge),
            'ml-2 px-2',
          )}
          variant="secondary"
        >
          L2
        </Badge>
      )}
      <span className={classNames('float-right pt-1', css(styles.GMVText))}>
        {`${formatCurrency(option.gmv) || ''}`}
      </span>
    </div>
  );
};

function NestedFilterItem({
  option,
  selectedOptions,
  setSelectedOptions,
  isRootNode = false,
  autoCollapseWhenInactive = false,
  showSubOptions = true,
  showCategoryBadges = false,
}) {
  const [expanded, setExpanded] = useState(false);

  const { label, value, subOptions, iconImageUrl, gmv } = option;
  // separating selected options for current level and deep levels
  const [selectedOption, ...restSelectedOptions] = selectedOptions || [];

  const isActive = value === selectedOption?.value;

  useEffect(() => {
    if (autoCollapseWhenInactive) {
      setExpanded(value === selectedOption?.value);
    }
  }, [selectedOption?.value, value, setExpanded, autoCollapseWhenInactive]);

  // no child options
  if (isLeafNode(option)) {
    return (
      <LeafMenuItem
        option={option}
        setSelectedOptions={setSelectedOptions}
        isRootNode={isRootNode}
        selectedOption={selectedOptions?.[0]}
        showCategoryBadges={showCategoryBadges}
      />
    );
  }

  const hideChildren = shouldHideChildren(option) || !showSubOptions;
  const noChildrenSelected = !restSelectedOptions?.length;

  return (
    <>
      <div
        className={classNames(
          'd-flex align-items-center pl-0',
          css(isRootNode ? styles.rootNode : styles.leafNode),
          isActive &&
            (noChildrenSelected || hideChildren ? css(styles.activeLeaf) : 'font-weight-bold'),
          css(styles.bottomBorder),
        )}
        onClick={() => {
          setSelectedOptions([option]);
          setExpanded(!isActive || !noChildrenSelected || !expanded);
        }}
      >
        <div className={classNames('w-100', css(styles.node))}>
          <Image
            src={iconImageUrl}
            thumbnail
            fluid
            className={css(
              isActive && noChildrenSelected ? styles.l1ActiveIcon : styles.l1InactiveIcon,
            )}
          />
          {label}
          {showCategoryBadges && (
            <Badge
              className={classNames(
                css(
                  styles.categoryBadge,
                  isActive && noChildrenSelected ? styles.activeBadge : styles.InactiveBadge,
                ),
                'ml-2 px-2',
              )}
              variant="secondary"
            >
              L1
            </Badge>
          )}
          <span className={classNames('float-right pt-1', css(styles.GMVText))}>
            {`${formatCurrency(gmv) || ''}`}
          </span>
        </div>
        {!hideChildren && (
          <div
            className={css(styles.node)}
            onClick={e => {
              e.stopPropagation();
              setExpanded(!expanded);
            }}
          >
            <FontAwesomeIcon
              className="ml-1"
              icon={expanded ? faChevronUp : faChevronDown}
              size="lg"
              color={isActive && noChildrenSelected ? white : 'grey'}
            />
          </div>
        )}
      </div>

      <div
        className={classNames(
          (hideChildren || !expanded) && 'd-none',
          css(isRootNode ? styles.rootChildren : styles.children),
        )}
      >
        {subOptions?.map((subOption, index) => (
          <NestedFilterItem
            key={`${subOption.label}_${subOption.value}_${index}`}
            option={subOption}
            selectedOptions={restSelectedOptions}
            setSelectedOptions={childOptions => setSelectedOptions([option, ...childOptions])}
            autoCollapseWhenInactive={autoCollapseWhenInactive}
            showCategoryBadges={showCategoryBadges}
          />
        ))}
      </div>
    </>
  );
}

const styles = StyleSheet.create({
  children: {
    marginLeft: NEST_INDENTATION,
  },
  bottomBorder: {
    borderBottom: '1px solid ' + darkGrey2,
  },
  rootChildren: {
    marginLeft: ROOT_INDENTATION,
  },
  node: {
    paddingTop: 7,
    paddingBottom: 7,
  },
  rootNode: {
    paddingRight: 5,
  },
  leafNode: {
    paddingLeft: NEST_INDENTATION,
    paddingRight: 5,
  },
  activeLeaf: {
    color: white,
    borderRadius: 5,
    backgroundColor: auroMetalSaurus,
  },
  inactiveLeaf: {
    color: 'grey',
    borderRadius: 5,
    cursor: 'pointer',
  },
  l1ActiveIcon: {
    border: 0,
    filter: 'invert(1) saturate(100%) brightness(100%)',
    background: 'transparent',
  },
  l1InactiveIcon: {
    border: 0,
    filter: 'none',
    background: 'transparent',
  },
  GMVText: {
    fontSize: '0.84rem',
  },
  categoryBadge: {
    borderRadius: 10,
    fontSize: '0.75rem',
    fontWeight: 550,
  },
  activeBadge: {
    backgroundColor: white,
    color: grey,
  },
  InactiveBadge: {
    backgroundColor: grey,
    color: white,
  },
});

NestedFilterItem.propTypes = {
  option: PropTypes.shape({
    value: PropTypes.number || null,
    label: PropTypes.string.isRequired,
    subOptions: PropTypes.arrayOf(PropTypes.object),
    iconImageUrl: PropTypes.string,
    gmv: PropTypes.number,
  }).isRequired,
  selectedOptions: PropTypes.arrayOf(PropTypes.object),
  setSelectedOptions: PropTypes.func.isRequired,
  isRootNode: PropTypes.bool,
  autoCollapseWhenInactive: PropTypes.bool,
  showSubOptions: PropTypes.bool,
  showCategoryBadges: PropTypes.bool,
};

LeafMenuItem.propTypes = {
  option: PropTypes.shape({
    value: PropTypes.number || null,
    label: PropTypes.string.isRequired,
    gmv: PropTypes.number,
  }).isRequired,
  selectedOption: PropTypes.shape({
    value: PropTypes.number || null,
    label: PropTypes.string.isRequired,
  }),
  setSelectedOptions: PropTypes.func.isRequired,
  isRootNode: PropTypes.bool,
  showCategoryBadges: PropTypes.bool,
};

export { createOptionNode };
export default NestedFilterItem;
